import { Box, Typography, makeStyles } from "@material-ui/core";
import { GeoJSON, Map } from "react-leaflet";
import AspectRatioIcon from "@material-ui/icons/AspectRatio";
import CloseIcon from "@material-ui/icons/Close";
import React, { Fragment, useState } from "react";
import WorldmapModal from "./WorldmapModal";
import { COLORS_CONF } from "../../../common/Constants";

const useStyles = makeStyles(() => ({
  mapSec: {
    "& .leaflet-container": {
      height: "340px !important",
      backgroundColor: "#fff",
    },
    "& .leaflet-bottom": {
      display: "none",
    },
  },
  expandIcon: {
    float: "right",
    color: COLORS_CONF.primary.orange,
    margin: "3px 3px 0px 0px",
  },
  modelSection: {
    position: "absolute",
    top: "50%",
    height: "90%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    backgroundColor: "#fff",
    border: "#000",
    boxShadow: "24px",
    borderRadius: "4px",
    "&:focus-visible": {
      outline: "none",
    },
  },
  modalHeader: {
    color: "#211008",
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
    backgroundColor: "#b0d7f1",
  },
  modalBody: {
    backgroundColor: "#dbedf9",
    width: "100%",
    height: "calc(100% - 50px)",
    "& .leaflet-bottom": {
      display: "none",
    },
  },
}));

const WorldmapView = (props) => {
  const classes = useStyles();
  const {
    ZOOM_LEVEL,
    children,
    center,
    countryStyle,
    data,
    onEachCountry = "",
    title,
  } = props;
  const [open, setOpen] = useState(false);

  const handleCloseModel = () => setOpen(false);

  const handleOpen = () => setOpen(true);

  const renderMapModel = () => (
    <WorldmapModal openPopup={open} handleClosePopup={handleCloseModel}>
      <Box className={classes.modelSection}>
        <Box className={classes.modalHeader}>
          <Typography variant="h5">{title}</Typography>
          <CloseIcon onClick={handleCloseModel} />
        </Box>
        <Map
          className={classes.modalBody}
          center={center}
          zoom={2}
          scrollWheelZoom={false}
          doubleClickZoom={false}
        >
          <GeoJSON
            style={countryStyle}
            data={data}
            onEachFeature={onEachCountry}
          />
          {children}
        </Map>
      </Box>
    </WorldmapModal>
  );

  return (
    <Fragment>
      {renderMapModel()}
      <div className={classes.mapSec}>
        <AspectRatioIcon className={classes.expandIcon} onClick={handleOpen} />
        <Map
          style={{ height: "200px", width: "100%" }}
          center={center}
          zoom={ZOOM_LEVEL}
          scrollWheelZoom={false}
          doubleClickZoom={false}
        >
          <GeoJSON
            style={countryStyle}
            data={data}
            onEachFeature={onEachCountry}
          />
          {children}
        </Map>
      </div>
    </Fragment>
  );
};

export default WorldmapView;
