import { FusionCharts } from '../../../configurations';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import MsColumn2D from 'fusioncharts/fusioncharts.treemap';
import React from 'react';
import ReactFC from 'react-fusioncharts';

ReactFC.fcRoot(FusionCharts, MsColumn2D, FusionTheme);

// FusionCharts.addDep(InteractiveTooltip);

const ColumnChart = (props) => {
    const chartConfig = {
        ...props.chartConfig,
        events: {
            renderComplete: props.renderCompleteHandler,
            dataPlotRollOver: props.dataPlotRollOverHandler,
            annotationClick: props.annotationClicked,
            chartRollOut: props.chartRollOut,
            dataLabelRollOver: props.dataLabelRollOver,
            dataLabelRollOut: props.dataLabelRollOut
        },
    };

    chartConfig.dataSource.categories = props.data.categories;
    chartConfig.dataSource.dataset = props.data.dataset;
    chartConfig.dataSource.annotations = props.annotations;

    return (<ReactFC {...chartConfig} />);
};

export default ColumnChart;
