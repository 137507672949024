import axios from 'axios';
import fetchLogObj from '../common/log';

const TRANSFER_PROTOCOL = 'https';
const HOST = process.env.REACT_APP_BE_END_POINT_HOST;

function sendLogUserAction(requestHeaders, requestData) {
    axios.request({
        url: 'api/account/log-user-action/',
        method: 'post',
        baseURL: `${TRANSFER_PROTOCOL}://${HOST}`,
        headers: requestHeaders,
        data: requestData,
    }).then(
        // requestSuccess, requestFailure
    );
}

/**
 * Function to handle the API calls of the application
 * @param {String} requestMethod - GET/POST/PATCH/DELETE
 * @param {String} requestAPI
 * @param {Object} requestData
 * @param {Function} requestSuccess
 * @param {Function} requestFailure
 */
function sendRequest(requestMethod, requestAPI, requestData, requestHeaders, requestSuccess, requestFailure, payload, responseType = 'json') {
    axios.request({
        url: requestAPI,
        method: requestMethod,
        responseType,
        baseURL: `${TRANSFER_PROTOCOL}://${HOST}`,
        headers: requestHeaders,
        params: (requestMethod === 'get' || requestMethod === 'put') ? requestData : null,
        data: requestMethod === 'put' ? payload : requestData,
    }).then(requestSuccess, requestFailure);

    const logData = fetchLogObj(requestMethod, requestAPI);

    if (logData) {
        sendLogUserAction(requestHeaders, logData);
    }
}

export default sendRequest;
