import Charts from 'fusioncharts/fusioncharts.charts';
import { FusionCharts } from '../../../configurations';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import React from 'react';
import ReactFC from 'react-fusioncharts';

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

const DoughnutChart = (props) => {
    const chartConfigs = props.chartConfig;
    chartConfigs.dataSource.data = props.chartData;
    chartConfigs.dataSource.chart.defaultCenterLabel = props.centerLabel;
    chartConfigs.events = props.events
    return <ReactFC {...chartConfigs} />;
};

export default DoughnutChart;
