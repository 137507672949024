import { Redirect, Route } from 'react-router-dom';
import { authCentralState, getLoginDetail } from '../helper/commonHelper';
import { isExumaEnabled } from '../common/Constants';
import React from 'react';

const PublicRoute = ({ component: Component, ...rest }) => {
    const userData = getLoginDetail();
    const defaultView = userData && userData.profile && userData.profile.default_view;
    const path = isExumaEnabled ? (`/${defaultView || 'dashboard'}`) : '/dashboard';

    return (
        <Route
            {...rest}
            render={(props) => (
                authCentralState() ? <Redirect to={path} /> : <Component {...props} />
            )}
        />
    );
};

export default PublicRoute;
