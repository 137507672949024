import { DOUGHNUT_CHART_CONFIG } from '../common/charts/DefaultConfig';
import { RISK_CATEGORY_COLORS } from '../common/constants';
import { getRequestedHeader } from '../../helper/commonHelper';
import DoughnutChart from '../common/charts/DoughnutView';
import Loader from '../common/LoaderView';
import React, { useEffect, useState } from 'react';
import sendRequest from '../../httpRequest';

/**
 * Function to convert data specific to chart format
 * @param {Objcet} rawData - the data that is to be converted
 * @param {String} chartType
 */
const convertChartSpecificData = (rawData, chartType) => {
    if (chartType === 'doughnut') {
        let data = [];
        data = [{
            label: `${rawData.high.split('%')[0].split(' ')[0]}% High`,
            showValue: 0,
            value: rawData.high.split('%')[0],
            color: RISK_CATEGORY_COLORS.HIGH_RISK,
            labelFontColor: RISK_CATEGORY_COLORS.HIGH_RISK,
        }, {
            label: `${rawData.critical.split('%')[0].split(' ')[0]}% Critical`,
            showValue: 0,
            value: rawData.critical.split('%')[0],
            color: RISK_CATEGORY_COLORS.CRITICAL_RISK,
            labelFontColor: RISK_CATEGORY_COLORS.CRITICAL_RISK,
        }, {
            label: `${rawData.low.split('%')[0].split(' ')[0]}% Low`,
            showValue: 0,
            value: rawData.low.split('%')[0],
            color: RISK_CATEGORY_COLORS.LOW_RISK,
            labelFontColor: RISK_CATEGORY_COLORS.LOW_RISK,
        }, {
            label: `${rawData.moderate.split('%')[0].split(' ')[0]}% Moderate`,
            showValue: 0,
            value: rawData.moderate.split('%')[0],
            color: RISK_CATEGORY_COLORS.MODERATE_RISK,
            labelFontColor: RISK_CATEGORY_COLORS.MODERATE_RISK,
        }];

        return data;
    } if (chartType === 'column') {
        const data = [];
        data.categories = [{
            category: [{
                label: 'Min Rating',
            }, {
                label: 'Average Rating',
            }, {
                label: 'Max Rating',
            }],
        }];

        data.dataset = [{
            seriesname: 'Supply Wisdom',
            data: [{
                value: rawData.min_rating,
            }, {
                value: rawData.average_rating,
            }, {
                value: rawData.max_rating,
            }],
        }, {
            seriesname: 'Your Portfolio',
            data: [{
                value: rawData.my_min_rating,
            }, {
                value: rawData.my_average_rating,
            }, {
                value: rawData.my_max_rating,
            }],
        }];

        return data;
    }
};

const CompositeScore = (props) => {
    const [compositeScoreData, setCompositeScoreData] = useState({});
    const [loading, setLoadingStatus] = useState(false);

    const { raasAccountSelected, label } = props;

    useEffect(() => {
        if (props.targetId) {
            setLoadingStatus(true);
            const requestData = {
                target_type: props.targetId,
                report_type: props.reportId,
                quarter: props.quarter,
                year: props.year,
                label: props.label,
                client_org: raasAccountSelected.id,
            };

            sendRequest('get', 'api/v2/risk/snapshot-score/composite/', requestData, getRequestedHeader(), compositeScoreRequestSuccess, requestFailure);
        }
    }, [props.targetId, props.reportId, props.quarter, props.year, label, raasAccountSelected]);

    /**
    * Callback after getting findings by composite score
    * @param {Object} res
    */
    const compositeScoreRequestSuccess = (res) => {
        setLoadingStatus(false);
        setCompositeScoreData(res.data);
    };

    /**
     * Function to handle request failure
     * @param {Object} error
     */
    const requestFailure = (error) => {
        setLoadingStatus(false);
        console.log(error);
    };

    let compositeScoreView = null;
    if (Object.keys(compositeScoreData).length) {
        const chartData = convertChartSpecificData(compositeScoreData, 'doughnut');
        DOUGHNUT_CHART_CONFIG.dataSource.chart.plotToolText = '$label';
        compositeScoreView = (
            <DoughnutChart
                chartData={chartData}
                chartConfig={DOUGHNUT_CHART_CONFIG}
                centerLabel={`${compositeScoreData.targets_count}<br>Targets`}
            />
        );
    }
    return (loading ? <Loader loading={loading} /> : compositeScoreView);
};

export default CompositeScore;
