import { Alert, AlertTitle } from "@material-ui/lab";
import { Typography } from "@material-ui/core";
import React from "react";
import { COLORS_CONF } from "../../common/Constants";

const NoDataElement = (props) => {
  const { content, style } = props;
  return (
    <Alert
      severity="info"
      style={{
        backgroundColor: COLORS_CONF.primary.beige,
        color: COLORS_CONF.primary.black,
        fontWeight: 'bold'
      }}
    >
      {content}
    </Alert>
  );
};

export default NoDataElement;
