import {
    getFormattedDate, getRequestedHeader, localStorageGetItem, localStorageSetItem,
} from '../../helper/commonHelper';
import { toast } from 'react-toastify';
import getIndexedDBData from './IndexedDbConnection';
import sendRequest from '../../httpRequest';

const requestFailure = (error) => {
    toast.error(error.message);
};

const idb = window.indexedDB;

function createIndexedDb(dispatch) {
    if (!idb) {
        console.log("browser don't have indexedDB");
        return;
    }

    const request = idb.open('sw-database', 1);

    request.onerror = (event) => {
        console.log('err', event);
    };

    request.onupgradeneeded = (e) => {
        const db = request.result;
        if (!db.objectStoreNames.contains('dataStore')) {
            db.createObjectStore('dataStore', {
                keyPath: 'name',
            });
        }
    };

    request.onsuccess = () => {
        const db = request.result;
        db.close();
    };
}

function getLabelTargets(dispatch) {
    const dataKeyName = 'labelTargets';
    const actionType = 'GET_TARGETS';
    const requestUrl = '/api/account/subscribed-targets/';

    getIndexedDBData(dispatch, dataKeyName, actionType, requestUrl);
}

function getIndustryVerticals(dispatch) {
    const dataKeyName = 'industryVertical';
    const actionType = 'ADD_INDUSTRY_VERTICAL';
    const requestUrl = 'api/risk/industryVerticals/';

    getIndexedDBData(dispatch, dataKeyName, actionType, requestUrl);
}

function getServiceLines(dispatch) {
    const dataKeyName = 'serviceLines';
    const actionType = 'ADD_SERVICE_LINES';
    const requestUrl = 'api/risk/serviceLines/';

    getIndexedDBData(dispatch, dataKeyName, actionType, requestUrl);
}

function getFAQs(dispatch) {
    const dataKeyName = 'faqs';
    const actionType = 'GET_ALL_FAQS';
    const requestUrl = '/api/v2/risk/textual-faq/';

    getIndexedDBData(dispatch, dataKeyName, actionType, requestUrl);
}

function getTargetTypes(dispatch) {
    const targetType = localStorageGetItem('targetTypes');
    const todayDate = getFormattedDate(new Date());
    if (targetType && targetType.synedDate === todayDate) {
        dispatch({
            type: 'ADD_TARGET_TYPES',
            payload: {
                targetType: targetType.targetTypes,
            },
        });
    } else {
        sendRequest(
            'get',
            '/api/v2/risk/target-type/',
            {},
            getRequestedHeader(),
            (res) => {
                const targetTypeResult = { synedDate: todayDate, targetTypes: res.data.results };
                localStorageSetItem('targetTypes', targetTypeResult);
                dispatch({
                    type: 'ADD_TARGET_TYPES',
                    payload: {
                        targetType: targetTypeResult.targetTypes,
                    },
                });
            },
            requestFailure,
        );
    }
}

// function getCountryList(dispatch, targetType) {
//     const targetCountries = localStorageGetItem('countriesList');
//     const todayDate = getFormattedDate(new Date());
//     if (targetCountries && targetCountries.synedDate === todayDate) {
//         dispatch({
//             type: 'ADD_TARGET_COUNTRY',
//             payload: {
//                 targetCountry: targetCountries.targetCountry,
//             },
//         });
//     } else {
//         sendRequest(
//             'get',
//             '/api/risk/targets',
//             {
//                 target_type: targetType,
//                 limit: 300,
//             },
//             getRequestedHeader(),
//             (res) => {
//                 const targetCountriesResult = { synedDate: todayDate, targetCountry: res.data.results };
//                 localStorageSetItem('countriesList', targetCountriesResult);
//                 dispatch({
//                     type: 'ADD_TARGET_COUNTRY',
//                     payload: {
//                         targetCountry: targetCountriesResult.targetCountry,
//                     },
//                 });
//             },
//             requestFailure,
//         );
//     }
// }

function getAllCategory(dispatch) {
    const categories = localStorageGetItem('categoryList');
    const todayDate = getFormattedDate(new Date());
    if (categories && categories.synedDate === todayDate) {
        dispatch({
            type: 'ADD_CATEGORY',
            payload: {
                category: categories.categories,
            },
        });
    } else {
        sendRequest(
            'get',
            'api/v2/risk/category/',
            {},
            getRequestedHeader(),
            (res) => {
                const categoriesList = { synedDate: todayDate, categories: res.data };
                localStorageSetItem('categoryList', categoriesList);
                dispatch({
                    type: 'ADD_CATEGORY',
                    payload: {
                        category: categoriesList.categories,
                    },
                });
            },
            requestFailure,
        );
    }
}

function getVideos(dispatch) {
    const videos = localStorageGetItem('videoTutorial');
    const todayDate = getFormattedDate(new Date());
    if (videos && videos.synedDate === todayDate) {
        dispatch({
            type: 'GET_ALL_VIDEOS',
            payload: {
                allVideos: videos.videoTutorial,
            },
        });
    } else {
        sendRequest(
            'get',
            '/api/v2/risk/video-faq/',
            {},
            getRequestedHeader(),
            (res) => {
                const videosData = { synedDate: todayDate, videoTutorial: res.data };
                localStorageSetItem('videoTutorial', videosData);
                dispatch({
                    type: 'GET_ALL_VIDEOS',
                    payload: {
                        allVideos: videosData.videoTutorial,
                    },
                });
            },
            requestFailure,
        );
    }
}

function getVideosWithCategory(dispatch) {
    sendRequest(
        'get',
        '/api/risk/video-faq-category/',
        {},
        getRequestedHeader(),
        (res) => {
            const videosData = { videoTutorialWithCategory: res.data && res.data.results ? res.data.results : [] };
            dispatch({
                type: 'GET_ALL_VIDEOS_CATEGORY',
                payload: {
                    allVideosWithCategory: videosData.videoTutorialWithCategory,
                },
            });
        },
        requestFailure,
    );
}

function getSubcriptionTargetList(dispatch) {
    const subcriptionTargetList = localStorageGetItem('subcriptionTargetList');
    const todayDate = getFormattedDate(new Date());
    if (subcriptionTargetList && subcriptionTargetList.synedDate === todayDate) {
        dispatch({
            type: 'GET_TARGET_LIST',
            payload: {
                targetList: subcriptionTargetList.targetList,
            },
        });
    } else {
        sendRequest(
            'get',
            'api/account/SubcriptionTargetType/',
            {},
            getRequestedHeader(),
            (res) => {
                const list = { synedDate: todayDate, targetList: res.data };
                localStorageSetItem('subcriptionTargetList', list);
                dispatch({
                    type: 'GET_TARGET_LIST',
                    payload: {
                        targetList: list.targetList,
                    },
                });
            },
            requestFailure,
        );
    }
}

function getNotificationPreference(dispatch) {
    const preference = localStorageGetItem('notificationPreference');
    const todayDate = getFormattedDate(new Date());
    if (preference && preference.synedDate === todayDate) {
        dispatch({
            type: 'GET_NOTIFICATION_PREFERENCE',
            payload: {
                preference: preference.preferenceList,
            },
        });
    } else {
        sendRequest(
            'get',
            'api/account/preference/',
            {},
            getRequestedHeader(),
            (res) => {
                const list = { synedDate: todayDate, preferenceList: res.data.results };
                localStorageSetItem('notificationPreference', list);
                dispatch({
                    type: 'GET_NOTIFICATION_PREFERENCE',
                    payload: {
                        preference: list.preferenceList,
                    },
                });
            },
            requestFailure,
        );
    }
}

export {
    getLabelTargets, getIndustryVerticals, getServiceLines,
    getTargetTypes, getAllCategory, createIndexedDb,
    getFAQs, getVideos, getVideosWithCategory, getSubcriptionTargetList, getNotificationPreference,
};
