let defaultLoginState = {
    accessToken: '',
    loginDetail:{}
};

function AccessTokenReducer(state = defaultLoginState, action){
    switch(action.type){
        case 'ADD_ACCESS_TOKEN': 
            return {...state,accessToken:  action.payload};
        case 'ADD_LOGIN_USER_DETAIL':
            return {...state,loginDetail:  action.payload};
        default: 
            return state;
    }
}

export default AccessTokenReducer;