import { Box, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import bannerImg from '../Images/swLogoBanner.jpg';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const breakpoints = createBreakpoints({});

const useStyles = makeStyles(() => ({
    body: {
        textAlign: 'center',
        marginTop: '30px',
        width: '80%',
        margin: 'auto',
    },
    exuMain: {
        maxWidth: '1050px',
        margin: '10px auto',
        [breakpoints.down('xs')]: {
            padding: '0px 8px',
        },
    },
    blueHeading: {
        color: '#0088bb',
        fontSize: '24px',
        textAlign: 'center',
        padding: '14px',
    },
    exuCard: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#e0f1ff',
        borderRadius: '10px',
        padding: '12px',
    },
    exuIcon: {
        width: '20%',
    },
    exuText: {
        color: '#757575',
        marginLeft: '18px',
    },
    blueHeadingsub: {
        color: '#0088bb',
        padding: '20px 0px 0px 0px',
        fontSize: '18px',
    },
    paraText: {
        fontSize: '16px',
        textAlign: 'justify',
    },
    subBox: {
        border: '1px solid #b5b5b5',
        borderRadius: '5px',
        padding: '5px',
        marginTop: '10px',
        marginBottom: '15px',
    },
    exusubIcon: {
        width: '30%',
    },
    footerClass: {
        backgroundColor: '#e0f1ff',
        marginBottom: '-10px',
        padding: '20px',
        textAlign: 'justify',
    },
    bulletStyle: {
        '& li': {
            '&::before': {
                content: '',
                color: 'red',
                fontWeight: 'bold',
                display: 'inline-block',
                width: '1em',
                marginLeft: '-1em',
            },
        },
    },
}));

function ExumaDetails() {
    const classes = useStyles();
    return (
        <div>
            <img src={bannerImg} style={{ width: '25%', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} alt="Exuma" />
            <div className={classes.exuMain}>

                <Typography variant="h6" className={classes.blueHeadingsub}>
                The new dashboards include some exciting robust feature sets, including:
                </Typography>
            
                <Typography style={{ paddingTop: '15px', }} variant="h6">
                Alert Analytics Dashboard
                </Typography>
                <ul>
                    <li>Tailored for analyzing alert data</li>
                    <li>Track alerts by criticality</li>
                    <li>View historical risk trends</li>
                    <li>Visualize portfolio event trends</li>
                </ul>
                <Typography variant="h6">
                Third-Party Analytics Dashboard
                </Typography>
                <ul>
                    <li>Analyze third-party risk data</li>
                    <li>Search and view risk trends and metrics for targeted third parties</li>
                    <li>Prioritize third parties by composite or domain risk rating</li>
                    <li>Targeted side-by-side comparison of third parties in your portfolio</li>
                </ul>
                <Typography variant="h6">
                Dashboard Settings
                </Typography>
                <ul>
                    <li>Your landing page for premium services</li>
                    <ul>
                        <li>Premium Services:</li>
                        <ul>
                            <li>Dashboard Customization – reach out to your customer success manager for more information to customize your dashboards per your individual needs!</li>
                        </ul>
                                   </ul>
                </ul>

            </div>
            <Box className={classes.footerClass}>
                <div className={classes.exuMain}>
                    <Typography variant="h6">
                    If you have any questions or concerns, please don't hesitate to reach out to your customer success manager or me directly at <a href="jennaw@supplywisdom.com">jennaw@supplywisdom.com</a>
                    </Typography>
                </div>
            </Box>

        </div>
    );
}

export default ExumaDetails;
