import { Box, Divider } from '@material-ui/core';

import {
    RAAS_CHAMPION_PLOT_COLORS,
    RISK_CATEGORY_COLORS,
} from '../common/constants';
import { createRiskDifferentiator } from '../common/riskDifferentiator';
import { getRequestedHeader } from '../../helper/commonHelper';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import Backdrop from '@material-ui/core/Backdrop';
import ColumnChart from '../common/charts/ColumnView';
import CustomRadio from '../common/CustomRadio';
import Fade from '@material-ui/core/Fade';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Loader from '../common/LoaderView';
import Modal from '@material-ui/core/Modal';
import NoDataElement from '../common/NoDataElement';
import RadioGroup from '@material-ui/core/RadioGroup';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Typography from '@material-ui/core/Typography';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import customTooltip from '../common/customTooltip';
import sendRequest from '../../httpRequest';

const breakpoints = createBreakpoints({});

const COLUMN_CHART_CONFIG = {
    type: 'mscolumn2d',
    width: '100%',
    height: '500',
    dataSource: {
        chart: {
            chartBottomMargin: '0',
            chartTopMargin: '0',
            chartLeftMargin: '0',
            chartRightMargin: '0',
            legendPosition: 'top-left',
            showBorder: '0',
            bgColor: '#ffffff',
            canvasBorderThickness: '0',
            showXAxisLine: '1',
            plotSpacePercent: '80',
            showAlternateHGridColor: '0',
            divLineDashed: '1',
            plotPaddingPercent: '35',
            legendShadow: '0',
            drawCustomLegendIcon: '1',
            legendItemFontSize: '16',
            labelFontSize: '16',
            labelPadding: '20',
            showTooltip: '0',
            showyaxisline: '1',
            yAxisValuesPadding: '10',
            yAxisValueFontSize: '14',
            showPlotBorder: '0',
            usePlotGradientColor: '0',
            legendBorderThickness: '0',
            yAxisMaxValue: '10',
            yAxisMinValue: '0',
            paletteColors: '#00C0AA, #9B69E0',
            rotateValues: 1,
        },
    },
};

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        position: 'relative',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        width: '20%',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        '&:focus': {
            outline: 'none',
        },
    },
    poupHr: {
        margin: '10px -32px',
    },

    ratingFilter: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    mainSecPart: {
        border: '1px solid #7070704B',
        padding: '10px',
        marginTop: '25px',
    },

    mainSelectBox: {
        width: '35%',
    },
    subheading: {
        float: 'left',
        fontFamily: 'Gilroy-Semibold',
        fontSize: '18px',
    },

    chartContainer: {
        marginTop: '19px',
    },
    categoryCont: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}));

// Style for Select
const customSelectStyles = {
    option: (provided, state) => ({
        padding: '10px',
    }),
    control: (base, state) => ({
        ...base,
        width: '100%',
        height: '46px',
        backgroundColor: '#D5DEE112',
        padding: '4px 12px',
        borderRadius: '2px',
        fontSize: '16px',
        fontFamily: 'Gilroy-Semibold',
        [breakpoints.between('sm', 'md')]: {
            marginTop: '7px',
        },
        [breakpoints.between('1000', '1350')]: {
            marginTop: '0px',
        },
    }),
    menuList: (base) => ({
        ...base,
        fontSize: '18px',
        fontFamily: 'Gilroy-Semibold',
    }),
};

/**
 * Function to convert data specific to chart format
 * @param {String} currentCategory
 * @param {Object} rawData
 * @param {String} chartType
 * @param {Boolean} isRaasChampion
 * @param {Object} selectedAccount
 * @param {Object} secondOrg
 */
const convertChartSpecificData = (
    currentCategory,
    rawData,
    chartType,
    isRaasChampion,
    selectedAccount,
    secondOrg
) => {
    if (chartType === 'doughnut') {
        let data = [];
        data = [
            {
                label: 'Critical',
                value: rawData.critical.split('%')[0],
                color: RISK_CATEGORY_COLORS.CRITICAL_RISK,
                labelFontColor: '#ffffff',
            },
            {
                label: 'High',
                value: rawData.high.split('%')[0],
                color: RISK_CATEGORY_COLORS.HIGH_RISK,
                labelFontColor: '#ffffff',
            },
            {
                label: 'Moderate',
                value: rawData.moderate.split('%')[0],
                color: RISK_CATEGORY_COLORS.MODERATE_RISK,
            },
            {
                label: 'Low',
                value: rawData.low.split('%')[0],
                color: RISK_CATEGORY_COLORS.LOW_RISK,
                labelFontColor: '#ffffff',
            },
        ];

        return data;
    } if (chartType === 'column') {
        const data = [];
        data.categories = [
            {
                category: [
                    {
                        label: 'Min Rating',
                    },
                    {
                        label: 'Average Rating',
                    },
                    {
                        label: 'Max Rating',
                    },
                ],
            },
        ];
        // Prepare chart specific data for Raas champion
        if (isRaasChampion && Object.keys(selectedAccount).length) {
            if (rawData.sw && rawData.org_1) {
                data.dataset = [
                    {
                        seriesname: 'Supply Wisdom Portfolio',
                        color: RAAS_CHAMPION_PLOT_COLORS.SUPPLY_WISDOM,
                        data: [
                            {
                                value: rawData.sw.min_rating,
                            },
                            {
                                value: rawData.sw.average_rating,
                            },
                            {
                                value: rawData.sw.max_rating,
                            },
                        ],
                    },
                    {
                        seriesname: selectedAccount.name,
                        color: RAAS_CHAMPION_PLOT_COLORS.ORG_1,
                        data: [
                            {
                                value: rawData.org_1[0].min_rating,
                            },
                            {
                                value: rawData.org_1[0].average_rating,
                            },
                            {
                                value: rawData.org_1[0].max_rating,
                            },
                        ],
                    },
                ];

                // If second org also selected then plot data for SW, org_1 and org_2
                if (secondOrg && rawData.org_2) {
                    data.dataset.push({
                        seriesname: secondOrg.name,
                        color: RAAS_CHAMPION_PLOT_COLORS.ORG_2,
                        data: [
                            {
                                value: rawData.org_2[0].min_rating,
                            },
                            {
                                value: rawData.org_2[0].average_rating,
                            },
                            {
                                value: rawData.org_2[0].max_rating,
                            },
                        ],
                    });
                }
            }
        } else {
            data.dataset = [
                {
                    seriesname: 'Supply Wisdom Portfolio',
                    data: [
                        {
                            value: rawData.min_rating,
                        },
                        {
                            value: rawData.average_rating,
                        },
                        {
                            value: rawData.max_rating,
                        },
                    ],
                },
            ];
            const series = {
                seriesname: 'Your Portfolio',
            };
            if (currentCategory === 'Composite Rating' && rawData.report_type) {
                series.data = [{
                    value: rawData.report_type[0].my_min_rating,
                }, {
                    value: rawData.report_type[0].my_average_rating,
                }, {
                    value: rawData.report_type[0].my_max_rating,
                }];
            } else {
                series.data = [
                    {
                        value: rawData.my_min_rating,
                    },
                    {
                        value: rawData.my_average_rating,
                    },
                    {
                        value: rawData.my_max_rating,
                    },
                ];
            }
            data.dataset.push(series);
        }
        return data;
    }
};

const PortfolioComparison = (props) => {
    const [categories, setCategories] = useState([]);
    const [porfolioComparisonData, setPortfoliComparisonData] = useState({});
    const [raasComparison, setRaasComparison] = useState({});
    const [currentCategory, setCurrentCategory] = useState({
        label: 'Composite Rating',
        value: 0,
    });
    const [loading, setLoadingStatus] = useState(false);
    const classes = useStyles();
    const [open, setopen] = useState(false);
    const [secondOrg, setSecondOrg] = useState('');
    const [addAccountState, setAddAccountState] = useState(true);
    const [tooltipHover, setTooltipHover] = useState('');
    const dispatch = useDispatch();

    const { raasAccount, actionProps, isRaasChampion } = props;

    useEffect(() => {
        const categoryId = currentCategory.value;
        if (props.categories.length && props.targetId) {
            const target_categories = [];
            props.categories.map((cat, index) => {
                if (cat.target_type === props.targetId) {
                    target_categories.push(cat);
                }
            });
            setCategories(target_categories);
        }
        const requestData = {
            category: categoryId,
            target_type: props.targetId,
            report_type: props.reportId,
            quarter: props.quarter,
            year: props.year,
            label: props.label,
        };
        if (Object.keys(raasAccount).length) {
            const orgId = raasAccount.id;
            requestData.org_1 = orgId;
            // Set org_2 id if second organization is selected
            if (secondOrg) {
                setLoadingStatus(true);
                requestData.org_2 = secondOrg.id;
                sendRequest(
                    'get',
                    'api/v2/risk/raas-comparison/',
                    requestData,
                    getRequestedHeader(),
                    raasComparsionSuccess,
                    requestFailure
                );
            }
        }
    }, [secondOrg]);

    useEffect(() => {
        setSecondOrg('');
        setAddAccountState(true);
        const categoryId = currentCategory.value;
        setLoadingStatus(true);
        if (props.categories.length && props.targetId) {
            const target_categories = [];
            props.categories.map((cat, index) => {
                if (cat.target_type === props.targetId) {
                    target_categories.push(cat);
                }
            });
            setCategories(target_categories);
        }
        const requestData = {
            category: categoryId,
            target_type: props.targetId,
            report_type: props.reportId,
            quarter: props.quarter,
            year: props.year,
            label: props.label,
        };

        if (isRaasChampion && Object.keys(raasAccount).length) {
            const orgId = raasAccount.id;
            requestData.org_1 = orgId;
            sendRequest(
                'get',
                'api/v2/risk/raas-comparison/',
                requestData,
                getRequestedHeader(),
                portfolioComparisonSuccess,
                requestFailure
            );
        } else {
            sendRequest(
                'get',
                'api/v2/risk/snapshot-score/comparison/',
                requestData,
                getRequestedHeader(),
                portfolioComparisonSuccess,
                requestFailure
            );
        }
    }, [
        props.targetId,
        props.reportId,
        props.year,
        props.label,
        props.quarter,
        currentCategory,
        raasAccount,
    ]);

    /**
   * Function to handle request failure
   * @param {Object} error
   */
    const requestFailure = (error) => {
        setLoadingStatus(false);
        console.log(error);
    };

    const raasComparsionSuccess = (res) => {
        setLoadingStatus(false);
        setRaasComparison(res.data);
    };

    /**
   * Callback function on portfolio comparison data success
   * @param {Object} res
   */
    const portfolioComparisonSuccess = (res) => {
        setLoadingStatus(false);
        setPortfoliComparisonData(res.data);
    };

    /**
   * Function to handle category dropdown change
   * @param {Object} newValue
   */
    const handleCategoryChange = (newValue) => {
        setCurrentCategory(newValue);
    };

    /**
   * Function to handle click of add account
   * @param {Object} event
   */
    const AnnotationItemClicked = (event) => {
        const addAccountIds = [
            'AddAccountLine1',
            'AddAccountLine2',
            'AddAccountLabel',
            'AddAccountCircle',
        ];
        const removeAccountIds = ['RemoveAccountCircle', 'RemoveAccountLine'];
        if (addAccountIds.includes(event.data.annotationId)) {
            setopen(true);
        } else if (removeAccountIds.includes(event.data.annotationId)) {
            setSecondOrg('');
            setAddAccountState(true);
        }
    };

    /**
   * Function to handle selection of second organization for Raas Champion
   * @param {Object} event
   */
    const handleSecondOrgUpdate = (event) => {
        const orgName = event.target.value;
        const orgDetails = props.raasOrganizations.find((org) => org.name === orgName);
        setSecondOrg(orgDetails);
        setopen(false);
        setAddAccountState(false);
    };

    const chartRollOutHandler = (evtObj) => {
        // Get the annotations object from fusioncharts object
        const annotations = evtObj && evtObj.sender.annotations;
        annotations.hide('CalloutBase');
        annotations.hide('CalloutTriangle');
        annotations.hide('CalloutLabel1');
        annotations.hide('CalloutLabel2');
    };

    /**
     * Function to show data on label hover
     * @param {Object} eventObj
     */
    const handleLabelRollOver = (eventObj) => {
        const { dataIndex } = eventObj.data;
        if (dataIndex === 0) {
            // Min Rating
            setTooltipHover('Our model calculates the average lowest score for Supply Wisdom portfolio and Client portfolio');
        } else if (dataIndex === 1) {
            // Average Rating
            setTooltipHover('Our model calculates the average mid point score for Supply Wisdom portfolio and Client portfolio');
        } else {
            // Max Rating
            setTooltipHover('Our model calculates the average of max score for Supply Wisdom portfolio and Client portfolio.');
        }
    };

    /**
     * Function to close account modal
     */
    const closeModal = () => {
        setopen(false);
    };

    let portfolioComparisonView = null;
    if (Object.keys(porfolioComparisonData).length || isRaasChampion) {
        const portfolioData = (secondOrg ? raasComparison : porfolioComparisonData);
        const chartData = convertChartSpecificData(
            currentCategory.label,
            portfolioData,
            'column',
            isRaasChampion,
            raasAccount,
            secondOrg,
        );
        const annotations = {
            showbelow: '0',
            groups: [
                {
                    id: 'Callout',
                    items: [
                        {
                            id: 'CalloutBase',
                            type: 'rectangle',
                            radius: '5',
                            fillColor: '#F5F5F5',
                        },
                        {
                            id: 'CalloutTriangle',
                            type: 'polygon',
                            sides: '3',
                            startangle: '270',
                            fillColor: '#F5F5F5',
                            radius: '9',
                        },
                        {
                            id: 'CalloutLabel1',
                            type: 'Text',
                            fontSize: '10',
                            underline: '1',
                            bold: '1',
                            color: '#428BCA',
                            link:
                'n-https://www.fusioncharts.com/dev/chart-guide/chart-configurations/annotations/annotation-references',
                        },
                        {
                            id: 'CalloutLabel2',
                            type: 'Text',
                            fontSize: '10',
                            bold: '1',
                        },
                    ],
                },
            ],
        };

        if (isRaasChampion && Object.keys(raasAccount).length) {
            if (addAccountState) {
                annotations.groups[0].items.push({
                    id: 'AddAccountLabel',
                    type: 'Text',
                    text: 'Add Account',
                    fontSize: '18',
                    font: 'Gilroy-Semibold',
                    color: '#428BCA',
                    bold: '1',
                    x: '$legendEndX + 80',
                    y: '$legendStarty + 16',
                });
                annotations.groups[0].items.push({
                    id: 'AddAccountCircle',
                    type: 'arc',
                    x: '$legendEndX + 10',
                    y: '$legendStarty + 16',
                    radius: '8',
                    innerRadius: '6',
                    color: '#428BCA',
                });
                annotations.groups[0].items.push({
                    id: 'AddAccountLine1',
                    type: 'line',
                    x: '$legendEndX + 6',
                    y: '$legendStarty + 16',
                    toX: '$legendEndX + 14',
                    toY: '$legendStarty + 16',
                    color: '#428BCA',
                });
                annotations.groups[0].items.push({
                    id: 'AddAccountLine2',
                    type: 'line',
                    x: '$legendEndX + 10',
                    y: '$legendStarty + 12',
                    toX: '$legendEndX + 10',
                    toY: '$legendStarty + 20',
                    color: '#428BCA',
                });
            } else {
                annotations.groups[0].items.push({
                    id: 'RemoveAccountCircle',
                    type: 'arc',
                    x: '$legendEndX + 10',
                    y: '$legendStarty + 16',
                    radius: '8',
                    innerRadius: '6',
                    color: '#c01610',
                });

                annotations.groups[0].items.push({
                    id: 'RemoveAccountLine',
                    type: 'line',
                    x: '$legendEndX + 6',
                    y: '$legendStarty + 16',
                    toX: '$legendEndX + 14',
                    toY: '$legendStarty + 16',
                    color: '#c01610',
                });
            }
        }

        portfolioComparisonView = (
            <ColumnChart
                chartConfig={COLUMN_CHART_CONFIG}
                data={chartData}
                annotations={annotations}
                annotationClicked={AnnotationItemClicked}
                renderCompleteHandler={createRiskDifferentiator}
                dataPlotRollOverHandler={customTooltip.bind(
                    null,
                    currentCategory.label,
                    portfolioData,
                    isRaasChampion && Object.keys(raasAccount).length,
                    props.targetId,
                    props.reportId
                )}
                dataLabelRollOver={handleLabelRollOver}
                dataLabelRollOut={() => setTooltipHover('')}
                chartRollOut={chartRollOutHandler}
            />
        );
    }

    let organizationRadio = [];
    if (props.raasOrganizations) {
        organizationRadio = props.raasOrganizations.filter((org, index) => {
            if (org.name !== raasAccount.name) {
                return true;
            }
        });
    }

    const categoryOptions = [];
    categories.map((cat) => {
        categoryOptions.push({
            label: cat.name,
            value: cat.id,
        });
    });

    if (currentCategory.label != 'Composite Rating') {
        categoryOptions.push({
            label: 'Composite Rating',
            value: 0,
        });
    }

    return (
        <Box className={classes.container}>
            <Box className={classes.mainContainer}>
                {loading ? (<Loader loading={loading} />) : (null)}
                {!isRaasChampion ? (
                    <Box className={classes.categoryCont}>
                        <Typography component="span" className={classes.subheading}>
                            Risk Rating
                        </Typography>
                        <Box className={classes.mainSelectBox}>
                            <Select
                                options={categoryOptions}
                                styles={customSelectStyles}
                                value={currentCategory}
                                onChange={handleCategoryChange}
                                components={{ IndicatorSeparator: () => null }}
                            />
                        </Box>
                    </Box>
                ) : (
                    <Box className={classes.mainSelectBox}>
                        <Select
                            options={categoryOptions}
                            styles={customSelectStyles}
                            value={currentCategory}
                            onChange={handleCategoryChange}
                            components={{ IndicatorSeparator: () => null }}
                        />
                        <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            className={classes.modal}
                            open={open}
                            onClose={closeModal}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500,
                            }}
                        >
                            <Fade in={open}>
                                <div className={classes.paper}>
                                    <Typography className={classes.searchAndTarget} variant="h6">
                                        Search Account to Compare
                                    </Typography>
                                    <Divider className={classes.poupHr} />
                                    <FormControl component="fieldset">
                                        <RadioGroup name="org" onChange={handleSecondOrgUpdate}>
                                            {organizationRadio.map((org, index) => (
                                                <FormControlLabel
                                                    key={org.name}
                                                    value={org.name}
                                                    control={<CustomRadio />}
                                                    label={org.name}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                            </Fade>
                        </Modal>
                    </Box>
                )}
            </Box>
            <Box className={classes.chartContainer}>
                { !loading ? portfolioComparisonView : null }
                { tooltipHover ? <NoDataElement content={tooltipHover} /> : null }
            </Box>
        </Box>
    );
};

export default PortfolioComparison;
