import LabelComponent from '../components/common/LabelComponentView';
import React from 'react';
import setLabels from '../redux/actions/labelAction';
import { checkEmptyObject, getLoginDetail, getToken } from '../helper/commonHelper';
import { useDispatch, useSelector } from 'react-redux';
import { loginUserDetail } from '../redux/actions/loginAction';

/**
 * This component acts as a container of the Label component.
 * @param {Object} props
 */
const LabelContainer = (props) => {
    const dispatch = useDispatch();
    const actionProps = { setLabels };
    let accessToken = useSelector((state) => state.LoginReducer.accessToken);
    const loginState = useSelector((state) => state.LoginReducer.loginDetail);
    const raasAccountSelected = useSelector((state) => state.NavbarReducer.raasAccountSelected);
    if (!accessToken) {
        accessToken = getToken() || accessToken;
    }

    if (checkEmptyObject(loginState)) {
        const data = getLoginDetail();
        dispatch(loginUserDetail(data));
    }
    const loginDetails = { loginUser: checkEmptyObject(loginState) ? getLoginDetail() : loginState.loginDetail };
    return <LabelComponent loginDetails={loginDetails} actionProps={actionProps} organizationId={typeof raasAccountSelected.id !== 'undefined' ? raasAccountSelected.id : loginDetails.loginUser.profile.organization} {...props} />;
};

export default LabelContainer;
