import {
    RISK_CATEGORY_COLORS,
    RISK_CATEGORY_RANGE,
} from './constants';

/**
 * Function to calculate the midpoints if y-axis line
 * @param {Number} startY
 * @param {Number} endY
 */
const calculateCoordinatesOfRiskElements = (yAxisMax, yAxisMin, startY, endY, isStatic) => {
    const proportions = [];
    const coordinates = [startY];

    let lowrisk = false; let modrisk = false; let highrisk = false; let
        immerisk = false;

    if (!isStatic) {
        const hrisk = RISK_CATEGORY_RANGE.HIGH_RISK_LIMIT;
        const lrisk = RISK_CATEGORY_RANGE.LOW_RISK_LIMIT;
        const mrisk = RISK_CATEGORY_RANGE.MODERATE_RISK_LIMIT;

        if (yAxisMin < lrisk) {
            lowrisk = true;
            if (yAxisMax > lrisk) {
                proportions.push(lrisk);
                modrisk = true;
                if (yAxisMax > mrisk) {
                    proportions.push(mrisk);
                    highrisk = true;
                    if (yAxisMax > hrisk) {
                        proportions.push(hrisk);
                        immerisk = true;
                    }
                }
            }
        }

        for (let i = proportions.length - 1; i >= 0; i--) {
            const unitPerPixel = (endY - startY) / (yAxisMax - yAxisMin);
            const increment = (unitPerPixel * (yAxisMax - yAxisMin - proportions[i]));
            coordinates.push(startY + increment);
        }

        coordinates.push(endY);

        return {
            coordinates,
            lowrisk,
            modrisk,
            highrisk,
            immerisk,
        };
    }

    const increment = (endY - startY) / 5; let
        prev = startY;
    for (let i = 5; i > 1; i--) {
        coordinates.push(prev + increment);
        prev += increment;
    }

    coordinates.push(endY);
    return {
        coordinates,
    };
};

/**
 * Function to convert the y-axis line to a risk differentiator component
 */
const createRiskDifferentiator = (eventObj) => {
    const SVG_NAMESPACE = 'http://www.w3.org/2000/svg';
    // 1. get the y-axis line component
    const parent = document.querySelectorAll('g[class*=axis-line-tick-bottom]');
    const child = parent[0].children[0];
    // 2. Extract start x,y and end x,y position
    const extractStrings = child.getAttribute('d').split('L');
    const extractStartString = extractStrings[0].split('M')[1];
    const startX = extractStartString.split(',')[0];
    const endY = Number(extractStartString.split(',')[1]);
    const endX = extractStrings[1].split(',')[0];
    const startY = Number(extractStrings[1].split(',')[1]);
    const differentiator = calculateCoordinatesOfRiskElements(10, 0, startY, endY);
    const arrLength = differentiator.coordinates.length;
    // Remove previously created elements for risk
    for (let i = 1; i <= 4; i += 1) {
        if (parent[0].childNodes[1]) parent[0].removeChild(parent[0].childNodes[1]);
    }

    if (differentiator.lowrisk) {
        const elementLow = document.createElementNS(SVG_NAMESPACE, 'path');
        const D_ATTR = `M${startX},${differentiator.coordinates[arrLength - 1]},L${endX},${differentiator.coordinates[arrLength - 2]}`;
        elementLow.setAttribute('d', D_ATTR);
        elementLow.setAttribute('stroke-opacity', '1');
        elementLow.setAttribute('stroke-width', 10);
        elementLow.setAttribute('stroke', RISK_CATEGORY_COLORS.LOW_RISK);
        parent[0].appendChild(elementLow);
    }

    if (differentiator.modrisk) {
        const element1 = document.createElementNS(SVG_NAMESPACE, 'path');
        const D_ATTR = `M${startX},${differentiator.coordinates[arrLength - 2]},L${endX},${differentiator.coordinates[arrLength - 3]}`;
        element1.setAttribute('d', D_ATTR);
        element1.setAttribute('stroke-opacity', '1');
        element1.setAttribute('stroke-width', 10);
        element1.setAttribute('stroke', RISK_CATEGORY_COLORS.MODERATE_RISK);
        parent[0].appendChild(element1);
    }

    if (differentiator.highrisk) {
        const element1 = document.createElementNS(SVG_NAMESPACE, 'path');
        const D_ATTR = `M${startX},${differentiator.coordinates[arrLength - 3]},L${endX},${differentiator.coordinates[arrLength - 4]}`;
        element1.setAttribute('d', D_ATTR);
        element1.setAttribute('stroke-opacity', '1');
        element1.setAttribute('stroke-width', 10);
        element1.setAttribute('stroke', RISK_CATEGORY_COLORS.HIGH_RISK);
        parent[0].appendChild(element1);
    }

    if (differentiator.immerisk) {
        const element1 = document.createElementNS(SVG_NAMESPACE, 'path');
        const D_ATTR = `M${startX},${differentiator.coordinates[arrLength - 4]},L${endX},${differentiator.coordinates[arrLength - 5]}`;
        element1.setAttribute('d', D_ATTR);
        element1.setAttribute('stroke-opacity', '1');
        element1.setAttribute('stroke-width', 10);
        element1.setAttribute('stroke', RISK_CATEGORY_COLORS.CRITICAL_RISK);
        parent[0].appendChild(element1);
    }
};

/**
 * Function to divide y-axis line with risks colours in timeseries chart
 */
const createStaticRiskBar = (isStatic) => {
    const SVG_NAMESPACE = 'http://www.w3.org/2000/svg';
    // 1. get the y-axis line component
    const parent = document.querySelectorAll('g[class*=axis-line-tick-bottom]');
    const child = parent[0].children[0];
    parent.innerHTML = '';
    // 2. Extract start x,y and end x,y position
    const extractStrings = child.getAttribute('d').split('L');
    const extractStartString = extractStrings[0].split('M')[1];
    const startX = extractStartString.split(',')[0];
    const endY = Number(extractStartString.split(',')[1]);
    const endX = extractStrings[1].split(',')[0];
    const startY = Number(extractStrings[1].split(',')[1]);
    const differentiator = calculateCoordinatesOfRiskElements(10, 0, startY, endY, isStatic);
    const arrLength = differentiator.coordinates.length;
    let D_ATTR;
    // Create info risk bar
    D_ATTR = `M${startX},${differentiator.coordinates[arrLength - 1]},L${endX},${differentiator.coordinates[arrLength - 2]}`;
    child.setAttribute('d', D_ATTR);
    child.setAttribute('stroke-width', 10);
    child.setAttribute('stroke', RISK_CATEGORY_COLORS.INFO_RISK);

    // Create low risk bar
    const element1 = document.createElementNS(SVG_NAMESPACE, 'path');
    D_ATTR = `M${startX},${differentiator.coordinates[arrLength - 2]},L${endX},${differentiator.coordinates[arrLength - 3]}`;
    element1.setAttribute('d', D_ATTR);
    element1.setAttribute('stroke-opacity', '1');
    element1.setAttribute('stroke-width', 10);
    element1.setAttribute('stroke', RISK_CATEGORY_COLORS.LOW_RISK);
    parent[0].appendChild(element1);

    // Create moderate risk bar
    const element2 = document.createElementNS(SVG_NAMESPACE, 'path');
    D_ATTR = `M${startX},${differentiator.coordinates[arrLength - 3]},L${endX},${differentiator.coordinates[arrLength - 4]}`;
    element2.setAttribute('d', D_ATTR);
    element2.setAttribute('stroke-opacity', '1');
    element2.setAttribute('stroke-width', 10);
    element2.setAttribute('stroke', RISK_CATEGORY_COLORS.MODERATE_RISK);
    parent[0].appendChild(element2);

    // Create high risk bar
    const element3 = document.createElementNS(SVG_NAMESPACE, 'path');
    D_ATTR = `M${startX},${differentiator.coordinates[arrLength - 4]},L${endX},${differentiator.coordinates[arrLength - 5]}`;
    element3.setAttribute('d', D_ATTR);
    element3.setAttribute('stroke-opacity', '1');
    element3.setAttribute('stroke-width', 10);
    element3.setAttribute('stroke', RISK_CATEGORY_COLORS.HIGH_RISK);
    parent[0].appendChild(element3);

    // Create critical risk bar
    const element4 = document.createElementNS(SVG_NAMESPACE, 'path');
    D_ATTR = `M${startX},${differentiator.coordinates[arrLength - 5]},L${endX},${differentiator.coordinates[arrLength - 6]}`;
    element4.setAttribute('d', D_ATTR);
    element4.setAttribute('stroke-opacity', '1');
    element4.setAttribute('stroke-width', 10);
    element4.setAttribute('stroke', RISK_CATEGORY_COLORS.CRITICAL_RISK);
    parent[0].appendChild(element4);
};

export {
    createRiskDifferentiator,
    createStaticRiskBar,
};
