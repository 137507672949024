import { useSelector } from 'react-redux';
import PortfolioComparison from '../components/dashboard/PortfolioComparisonView';
import React from 'react';
import setCategories from '../redux/actions/categoryAction';

/**
 * This component acts as a container for the PortfolioComparison component
 * @param {Object} props
 */
const PortfolioContainer = (props) => {
    const raasOrganizations = useSelector((state) => state.RaasOrganizationReducer.organizations);
    const isRaasChampion = useSelector((state) => state.NavbarReducer.isRaasChampion);
    const actionProps = { setCategories };
    return <PortfolioComparison raasOrganizations={raasOrganizations} {...props} actionProps={actionProps} isRaasChampion={isRaasChampion}/>;
};

export default PortfolioContainer;
