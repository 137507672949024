import { FusionCharts } from '../../../configurations';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import React from 'react';
import ReactFC from 'react-fusioncharts';
import Treemap from 'fusioncharts/fusioncharts.treemap';

ReactFC.fcRoot(FusionCharts, Treemap, FusionTheme);

const TreemapChart = (props) => {
    const chartConfigs = {
        type: 'treemap',
        width: '100%',
        height: '400',
        dataFormat: 'json',
        dataSource: {
            chart: {
                chartBottomMargin: '0',
                chartTopMargin: '-90',
                chartLeftMargin: '0',
                chartRightMargin: '0',
                showParent: '0',
                plotBorderColor: '#ffffff',
                plotBorderThickness: '3',
                showBorder: '0',
                bgColor: '#ffffff',
                showChildLabels: '1',
                labelFont: 'Gilroy-Semibold',
                labelFontSize: '18',
                labelFontColor: 'ffffff',
                labelGlow: '0',
                showlegend: '0',
                showToolTip: '0',
            },
            data: props.data,
            colorrange: props.colorrange,
        },
        events: {
            dataPlotClick: props.plotClickMethod,
        },
    };

    return (<ReactFC {...chartConfigs} />);
};

export default TreemapChart;
