const defaultState = {
    labelTargets: [],
    industryVertical: [],
    serviceLines: [],
    targetTypes: [],
    countryList: [],
    categoryList: [],
    faqs: [],
    videos: [],
    subcriptionTargetList: {},
    preferences: [],
};

function ParametertReducer(state = defaultState, action) {
    switch (action.type) {
    case 'GET_TARGETS':
        return {
            ...state,
            labelTargets: action.payload.targets,
        };
    case 'ADD_INDUSTRY_VERTICAL':
        return {
            ...state,
            industryVertical: action.payload.industryVertical,
        };
    case 'ADD_SERVICE_LINES':
        return {
            ...state,
            serviceLines: action.payload.serviceLines,
        };
    case 'ADD_TARGET_TYPES':
        return {
            ...state,
            targetTypes: action.payload.targetType,
        };
    case 'ADD_TARGET_COUNTRY':
        return {
            ...state,
            countryList: action.payload.targetCountry,
        };
    case 'ADD_CATEGORY':
        return {
            ...state,
            categoryList: action.payload.category,
        };
    case 'GET_ALL_FAQS':
        return {
            ...state,
            faqs: action.payload.allFaq,
        };
    case 'GET_ALL_VIDEOS':
        return {
            ...state,
            videos: action.payload.allVideos,
        };
    case 'GET_ALL_VIDEOS_CATEGORY':
        return {
            ...state,
            videosWithCategory: action.payload.allVideosWithCategory,
        };
    case 'GET_TARGET_LIST':
        return {
            ...state,
            subcriptionTargetList: action.payload.targetList,
        };
    case 'GET_NOTIFICATION_PREFERENCE':
        return {
            ...state,
            preferences: action.payload.preference,
        };
    default:
        return state;
    }
}

export default ParametertReducer;
