const defaultNavbarState = {
    showReportType: true,
    reportIdSelected: '',
    reportNameSelected: '',
    raasAccountSelected: {},
    isRaasChampion: false,
};

function NavbarReducer(state = defaultNavbarState, action) {
    switch (action.type) {
    case 'SET_REPORT_ID':
        return {
            ...state,
            reportIdSelected: action.payload.id,
        };
    case 'SHOW_HIDE_REPORT_TYPE':
        return {
            ...state,
            showReportType: action.payload.flag,
        };
    case 'SET_RAAS_ACCOUNT':
        return {
            ...state,
            raasAccountSelected: action.payload.raasAccount,
        };
    case 'SET_IS_RAAS_CHAMPION':
        return {
            ...state,
            isRaasChampion: action.payload.isRaasChampion,
        };
    default:
        return state;
    }
}

export default NavbarReducer;
