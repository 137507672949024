import { combineReducers } from 'redux';
import AlertsByTimeReducer from './alertsByTimeReducer';
import AnalystTargetsReducer from './askAnalyst';
import CategoriesReducer from './category';
import LabelsReducer from './labels';
import LoginReducer from './login';
import NavbarReducer from './navbar';
import ParametertReducer from './ParameterReducer';
import RaasOrganizationReducer from './raasOrg';
import TargetReducer from './targetReducer';
import TargetTypesReducer from './targetTypes';

const rootReducer = combineReducers({
    LoginReducer,
    TargetTypesReducer,
    RaasOrganizationReducer,
    CategoriesReducer,
    LabelsReducer,
    NavbarReducer,
    ParametertReducer,
    TargetReducer,
    AnalystTargetsReducer,
    AlertsByTimeReducer,
});

export default rootReducer;
