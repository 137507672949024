import { Box } from '@material-ui/core';
import {
    PERIOD_CONF, authCentralState, getDefaultSessionTimeout, localStorageGetItem, localStorageSetItem, sessionStorageGetItem
} from '../helper/commonHelper';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { isSessionTimeoutEnabled } from '../common/Constants';
import NavBarContainer from '../containers/NavbarContainer';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

const ProtectedRoute = ({ path, component: Component, ...rest }) => {
    const navbarPaths = ['/dashboard', '/targetList', '/targetProfile', '/KnowledgeManagement', '/articleDetails'];
    const reportType = navbarPaths.includes(path);
    const raasAccount = (path === '/dashboard');
    const history = useHistory();

    if (path === "*"){
        toast.info("Entered URL / Path is wrong, Please enter proper URL")
        return <Redirect to="/dashboard" />
    }

    /**
     * Function to clear cache related to auto logout
     */
    const clearAutoLogoutCache = () => {
        sessionStorage.removeItem('accessToken');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('sessionTime');
    };

    /**
     * Function to handle logout
     */
    const handleLogout = () => {
        clearAutoLogoutCache();
        const bannerPopup = localStorageSetItem('isBannerPopupShow');
        history.push('/');
        if (bannerPopup) {
            localStorageSetItem('isBannerPopupShow', bannerPopup);
        }
        toast.info('Security Alert: You have been automatically logged out due to inactivity. Please log in again to continue');
    };
    useEffect(() => {
        if (isSessionTimeoutEnabled) {
            window.addEventListener('click', () => {
                if (localStorage.getItem('sessionTime')) {
                    const startTime = localStorageGetItem('sessionTime');
                    const endTime = new Date().getTime();
                    const differenceInMilliseconds = endTime - startTime;
                    const mins = (differenceInMilliseconds / (PERIOD_CONF.milliseconds * PERIOD_CONF.seconds)) % PERIOD_CONF.minutes;
                    if (mins > getDefaultSessionTimeout()) {
                        handleLogout();
                    } else {
                        localStorageSetItem('sessionTime', new Date().getTime());
                    }
                }
            });
            localStorageSetItem('sessionTime', new Date().getTime());
        }
    }, []);

    return (
        <Route
            {...rest}
            path={path}
            render={(props) => (
                authCentralState() ? (
                    <Box>
                        <NavBarContainer reportType={reportType} raasAccount={raasAccount} />
                        <Component {...props} />
                    </Box>
                ) : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            )}
        />
    );
};

export default ProtectedRoute;
