import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import React from 'react';

const WorldmapModal = (props) => {
    const { children, openPopup, handleClosePopup } = props;
    return (
        <Modal
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableBackdropClick
            open={openPopup}
            onClose={handleClosePopup}
            closeAfterTransition
        >
            <Fade in={openPopup}>{children}</Fade>
        </Modal>
    );
};

export default WorldmapModal;
