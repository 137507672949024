import { isExumaEnabled } from '../../common/Constants';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import ClipLoader from 'react-spinners/ClipLoader';
import PacmanLoader from 'react-spinners/PacmanLoader';
import React from 'react';

const useStyles = makeStyles(() => ({
    backdrop: {
        backgroundColor: 'rgba(7, 33, 43, .1)',
        zIndex: 999,
    },
    inlineLoader: {
        width: '100%',
        textAlign: 'center',
    },
    screenLoader: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },

}));

const Loader = (props) => {
    const { color, isInline, style } = props;
    const classes = useStyles();
    const finalColor = color || '#FF691F';

    if (isInline) {
        return (
            <div className={classes.inlineLoader}>
                {isExumaEnabled
                    ? <ClipLoader loading color={finalColor} size={30} />
                    : <PacmanLoader loading color={finalColor} size={11} />}
            </div>
        );
    }

    return (
        <Backdrop open className={classes.backdrop}>
            <div className={classes.screenLoader} style={style}>
                {isExumaEnabled
                    ? <ClipLoader loading color={finalColor} size={60} />
                    : <PacmanLoader loading color={finalColor} size={20} />}
            </div>
        </Backdrop>
    );
};

export default Loader;
