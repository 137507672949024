function updateReportId(reportId) {
    return {
        type: 'SET_REPORT_ID',
        payload: {
            id: reportId,
        },
    };
}

function showOrHideReportType(flag) {
    return {
        type: 'SHOW_HIDE_REPORT_TYPE',
        payload: {
            flag,
        },
    };
}

function updateRaasAccountSelected(raasAccount) {
    return {
        type: 'SET_RAAS_ACCOUNT',
        payload: {
            raasAccount,
        },
    };
}

function setRaasChampion(isRaasChampion) {
    return {
        type: 'SET_IS_RAAS_CHAMPION',
        payload: {
            isRaasChampion,
        },
    };
}

export {
    updateReportId, showOrHideReportType, updateRaasAccountSelected, setRaasChampion,
};
