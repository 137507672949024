let defaultTargetState = {
    categories: []
};

function CategoriesReducer(state = defaultTargetState, action){
    switch(action.type){
        case 'SET_CATEGORIES': 
            return action.payload;
        default: 
            return state;
    }
}

export default CategoriesReducer;