import { DASHBOARD_IDS } from '../common/constants';
import { getDynamicDashbaordLink } from './common';
import EmbedView from './EmbedView';
import React, { useEffect, useState } from 'react';

const AnalystTargets = () => {
    const [link, setLink] = useState();
    useEffect(() => {
        const request = {
            dashboard_id: DASHBOARD_IDS.ANALYST_TARGETS,
        };
        getDynamicDashbaordLink(request, (src) => {
            setLink(src);
        });
    }, []);
    return (
        <EmbedView link={link} />
    );
};

export default AnalystTargets;
