import { DASHBOARD_IDS } from '../common/constants';
import { getDynamicDashbaordLink } from './common';
import EmbedView from './EmbedView';
import React, { useEffect, useState } from 'react';

const PrimaryAlertDashboard = () => {
    const [link, setLink] = useState();
    useEffect(() => {
        const request = {
            dashboard_id: DASHBOARD_IDS.PRIMARY_ALERT_DASHBOARD,
        };
        getDynamicDashbaordLink(request, (src) => {
            setLink(src);
        });
    }, []);
    //const link = "https://supplywisdom.sisense.com/app/main/dashboards/63b519363e387a0035c51937?embed=true&t=true";
    return (
        <EmbedView link={link} />
    );
};

export default PrimaryAlertDashboard;
