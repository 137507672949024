const defaultTimeState = {
    timePeriod: 48,
};

function AlertsByTimeReducer(state = defaultTimeState, action) {
    switch (action.type) {
    case 'ALERTS_BY_TIME_PERIOD':
        return action.payload;
    default:
        return state;
    }
}

export default AlertsByTimeReducer;
