import { Box, Grid } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { RISK_CATEGORY_COLORS, RISK_CATEGORY_RANGE } from "../constants";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import InfoIcon from "@material-ui/icons/Info";
import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import equalico from "../../../Images/equalico.svg";
import { COLORS_CONF } from "../../../common/Constants";

const breakpoints = createBreakpoints({});

const useStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: "collapse",
  },
  rowContainer: {
    border: "1px solid #E7EBEE",
  },
  rightBorder: {
    borderLeft: "1px solid #E7EBEE",
    width: "190px",
  },
  tooltip: {
    backgroundColor: "#ffffff",
    color: "#ffffff",
  },
  mainBackColr: {
    display: "flex",
    [breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  linkClass: {
    textDecoration: "none",
    color: "inherit",
    marginRight: 5,
    width: 155,
    textAlign: "right",
  },
  mainBackColrFirst: {
    width: "7px",
    height: "46px",
    backgroundColor: COLORS_CONF.primary.beige,
    marginRight: "-2px",
  },
  mainBackColrSecond: {
    width: "7px",
    height: "81px",
    backgroundColor: "#C01510",
    marginRight: "-2px",
  },
  mainBackColrThird: {
    width: "7px",
    height: "81px",
    backgroundColor: "#ED7D30",
    marginRight: "-2px",
  },
  mainBackColrFour: {
    width: "7px",
    height: "44px",
    backgroundColor: "#FEFD13",
    marginRight: "-2px",
  },
  mainBackColrFive: {
    width: "7px",
    height: "40px",
    backgroundColor: "#71b200",
    marginRight: "-2px",
  },
  tableMiddleSecThFirst: {
    backgroundColor: COLORS_CONF.primary.beige,
  },
  tableMiddleSecThird: {
    backgroundColor: COLORS_CONF.primary.beige,
    width: "20%",
    paddingRight: "10px",
  },
  tableMiddleSecThSecond: {
    backgroundColor: COLORS_CONF.primary.beige,
  },
  tableMiddleSec: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "200px",
    [breakpoints.between("1000", "1350")]: {
      width: "150px",
    },
    [breakpoints.between("sm", "md")]: {
      width: "115px",
    },
  },

  tableMiddleSecDots: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    textAlign: "left",
  },
  tableMiddleSecImg: {
    verticalAlign: "bottom",
  },
  fontSetPortImgg: {
    // width: '44%',
    verticalAlign: "inherit",
    // marginLeft: '7px',
    fontSize: "14px",
    color: "#3c3b3b",
  },
  arrowUpIcon: {
    verticalAlign: "middle",
    display: "inline-block",
    width: "25px",
    textAlign: "center",
  },
  arrowUpIconn: {
    fontSize: "30px",
    verticalAlign: "bottom",
    color: "red",
  },
  arrowDownIconn: {
    fontSize: "30px",
    verticalAlign: "bottom",
    color: "green",
  },
  headingRelated: {
    backgroundColor: COLORS_CONF.primary.beige,
    color: COLORS_CONF.primary.black,
    padding: "11px 20px",
    fontSize: "16px",
    fontWeight: "bold",
  },
  headingTop: {
    backgroundColor: COLORS_CONF.primary.beige,
    color: COLORS_CONF.primary.black,
    padding: "11px 20px",
    fontSize: "16px",
    fontWeight: "bold",
    [breakpoints.between("1024", "1350")]: {
      padding: "14px 20px",
    },
  },
  headingRelatedMiddle: {
    paddingTop: "15px",
  },
  headingRelatedMain: {
    padding: "15px",
  },
  mainMediumHighSec: {
    paddingTop: "5px",
  },
  mediumHighSec: {
    fontSize: "14px",
    padding: "5px 7px",
    textTransform: "capitalize",
  },
  mediumHighSecHigh: {
    fontSize: "14px",
    backgroundColor: "#ED7D31",
    padding: "4px 7px",
    color: "#fff",
  },
  mediumHighSecDate: {
    fontSize: "14px",
    marginRight: "30px",
    float: "right",
    [breakpoints.down("xs")]: {
      marginRight: "10px",
    },
  },
  fontSetSeeAll: {
    cursor: "pointer",
    textAlign: "right",
  },
  arrowRight: {
    border: "solid #FF691F",
    borderWidth: "0 3px 3px 0",
    display: "inline-block",
    padding: "3px",
    transform: "rotate(-45deg)",
  },
  arrowUpIconDig: {
    width: "32px",
    display: "inline-block",
    textAlign: "left",
  },
  alertTitle: {},
  alertContainer: {
    "border-bottom": "1px solid hsl(0, 0%, 80%)",
    paddingBottom: "10px",
    marginBottom: "30px",
    "& p": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    "&:hover": {
      "& $alertTitle": {
        color: COLORS_CONF.primary.orange,
      },
    },
  },

  relatedMob: {
    [breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  linkStyle: {
    textDecoration: "none",
    color: "inherit",
  },
}));

const CustomizedTooltip = withStyles(() => ({
  tooltip: {
    maxWidth: 220,
    fontSize: "14px",
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const TargetAlertList = (props) => {
  const {
    targetId,
    targetName,
    relatedAlerts,
    filteredCategories,
    targetType,
    isTargetList,
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const risk_categories = props.risk_categories || [];

  risk_categories.forEach((item, i) => {
    if (item.name === "Composite Risk") {
      risk_categories.splice(i, 1);
      risk_categories.unshift(item);
    }
  });

  return (
    <Grid container direction="row">
      <Grid
        item
        md={6}
        sm={12}
        lg={6}
        className={classes.mainBackColr}
        style={{ minHeight: 350 }}
      >
        <table className={classes.table}>
          <thead>
            <tr>
              <th className={classes.tableMiddleSecThFirst}>Risk Domain</th>
              <th className={classes.tableMiddleSecThFirst}>Today</th>
              <th
                className={classes.tableMiddleSecThird}
                style={{ paddingRight: "10px", textAlign: "center" }}
              >
                <CustomizedTooltip title="Change from previous quarter">
                  <span>Change</span>
                </CustomizedTooltip>
              </th>
            </tr>
          </thead>
          <tbody>
            {risk_categories.map((category, index) => {
              const scoreChange = category.score - category.old_score;
              let categoryClone;
              if (filteredCategories) {
                categoryClone = filteredCategories.find(
                  (subCategory) => category.name === subCategory.name
                );
              }

              let riskColor;
              if (
                category.score >= 0 &&
                category.score <= RISK_CATEGORY_RANGE.LOW_RISK_LIMIT
              ) {
                riskColor = RISK_CATEGORY_COLORS.LOW_RISK;
              } else if (
                category.score > RISK_CATEGORY_RANGE.LOW_RISK_LIMIT &&
                category.score <= RISK_CATEGORY_RANGE.MODERATE_RISK_LIMIT
              ) {
                riskColor = RISK_CATEGORY_COLORS.MODERATE_RISK;
              } else if (
                category.score > RISK_CATEGORY_RANGE.MODERATE_RISK_LIMIT &&
                category.score <= RISK_CATEGORY_RANGE.HIGH_RISK_LIMIT
              ) {
                riskColor = RISK_CATEGORY_COLORS.HIGH_RISK;
              } else if (
                category.score > RISK_CATEGORY_RANGE.HIGH_RISK_LIMIT &&
                category.score <= RISK_CATEGORY_RANGE.CRITICAL_RISK_LIMIT
              ) {
                riskColor = RISK_CATEGORY_COLORS.CRITICAL_RISK;
              }
              const borderLeft = `7px solid ${riskColor}`;

              // Add icon as per score change
              let scoreChangeIcon = null;
              if (scoreChange > 0) {
                scoreChangeIcon = (
                  <ArrowDropUpIcon className={classes.arrowUpIconn} />
                );
              } else if (scoreChange < 0) {
                scoreChangeIcon = (
                  <ArrowDropDownIcon className={classes.arrowDownIconn} />
                );
              } else {
                scoreChangeIcon = <img alt="scroe" src={equalico} />;
              }
              if (
                (categoryClone && categoryClone.selectStatus) ||
                (!categoryClone && props.risk_categories)
              ) {
                return (
                  <tr
                    className={classes.rowContainer}
                    style={{ borderLeft }}
                    key={category.name}
                  >
                    <td className={classes.tableMiddleSec}>
                      {" "}
                      <Box className={classes.tableMiddleSecDots}>
                        {category.name}{" "}
                      </Box>{" "}
                      <Box className={classes.tableMiddleSecImg}>
                        {" "}
                        {categoryClone ? (
                          categoryClone.description ? (
                            <CustomizedTooltip
                              title={categoryClone.description}
                              arrow
                              className={classes.tooltip}
                            >
                              <InfoIcon className={classes.fontSetPortImgg} />
                            </CustomizedTooltip>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}{" "}
                      </Box>
                    </td>
                    <td className={classes.rightBorder}>
                      <span className={classes.arrowUpIconDig}>
                        {category &&
                          category.score &&
                          parseFloat(category.score, 10).toFixed(2)}
                      </span>
                    </td>
                    <td style={{ width: "20%", padding: "0" }}>
                      <span className={classes.arrowUpIcon}>
                        {" "}
                        {scoreChangeIcon}{" "}
                      </span>
                      <span className={classes.arrowUpIconDig}>
                        {scoreChange > 0
                          ? `+${scoreChange.toFixed(2)}`
                          : scoreChange.toFixed(2)}
                      </span>
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </Grid>
      <Grid
        item
        md={6}
        sm={12}
        lg={6}
        className={classes.relatedMob}
        style={{ minHeight: 350 }}
      >
        <Typography
          className={isTargetList ? classes.headingRelated : classes.headingTop}
          variant="body2"
        >
          Related Alert(s)
        </Typography>
        <Box className={classes.headingRelatedMain}>
          {relatedAlerts
            ? props.relatedAlerts.map((alert) => {
                const colorKey =
                  alert.severity === "medium"
                    ? "MODERATE_RISK"
                    : `${alert.severity.toUpperCase()}_RISK`;
                const dateObj = new Date(alert.date)
                  .toLocaleString("en-GB", {
                    day: "2-digit",
                    month: "short",
                    year: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })
                  .replace(/ /g, "-")
                  .replace(",-", "\n");
                return (
                  <Link
                    key={alert.id}
                    to={`/alertProfile?alertId=${alert.id}`}
                    className={classes.linkStyle}
                  >
                    <Box className={classes.alertContainer} key={alert.title}>
                      <Typography
                        variant="body2"
                        className={classes.alertTitle}
                      >
                        {alert.title}
                      </Typography>
                      <Box className={classes.mainMediumHighSec}>
                        <span
                          className={classes.mediumHighSec}
                          style={{
                            backgroundColor: RISK_CATEGORY_COLORS[colorKey],
                            color: "#fff",
                          }}
                        >
                          {alert.severity}
                        </span>{" "}
                        <span className={classes.mediumHighSecDate}>
                          {dateObj}
                        </span>
                      </Box>
                    </Box>
                  </Link>
                );
              })
            : null}
          <Link
            to={`alertTargetList?name=${targetName}&id=${targetId}&type=${targetType}`}
            className={classes.linkClass}
          >
            <Box pt={2}>
              <Typography
                color="primary"
                className={classes.fontSetSeeAll}
                variant="subtitle1"
              >
                See All Alerts <i className={classes.arrowRight} />
              </Typography>
            </Box>
          </Link>
        </Box>
      </Grid>
    </Grid>
  );
};

// pathname: '/alertTargetList',
// search: `?name=${targetName}&id=${targetId}&type=${targetType}`,

export default TargetAlertList;
