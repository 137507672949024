import "./css/font.css";
import { LoaderContextProvider } from "./context";
import { ThemeProvider } from "@material-ui/styles";
import { ToastContainer } from "react-toastify";
import {
  createMuiTheme,
  makeStyles,
  withStyles,
} from "@material-ui/core/styles";

import CssBaseline from "@material-ui/core/CssBaseline";
import Loader from "./components/common/LoaderView";
import React from "react";
import Routes from "./routers/Route";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

const breakpoints = createBreakpoints({});

// Theme
const muiTheme = createMuiTheme({
  palette: {
    background: {
      default: "#fff",
    },
    primary: {
      main: "#FF691F",
    },
    primaryBlack: {
      main: "#211008",
    },
    secondary: {
      main: "#8F8FB3",
    },
    tonalOffset: 0.1,
  },

  typography: {
    h1: {
      fontSize: 36,
      fontFamily: "Gilroy-Semibold",
      fontWeight: 600,
      [breakpoints.down("sm")]: {
        fontSize: "24px",
        lineHeight: "35px",
      },
    },
    h2: {
      fontSize: 32,
      fontFamily: "Gilroy-Semibold",
      fontWeight: 600,
      [breakpoints.down("sm")]: {
        fontSize: "20px",
      },
    },
    h3: {
      fontSize: 28,
      fontFamily: "Gilroy-Semibold",
      fontWeight: 600,
      [breakpoints.down("sm")]: {
        fontSize: "20px",
      },
    },
    h4: {
      fontSize: 24,
      fontFamily: "Gilroy-Semibold",
      fontWeight: 600,
      [breakpoints.down("sm")]: {
        fontSize: "18px",
      },
    },
    h5: {
      fontSize: 21,
      fontFamily: "Gilroy-Semibold",
      fontWeight: 600,
      [breakpoints.down("xs")]: {
        fontSize: "16px",
      },
    },

    h6: {
      fontSize: 17,
      fontFamily: "Gilroy-Semibold",
      fontWeight: 600,
      [breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
    subtitle1: {
      fontSize: 16,
      fontFamily: "Gilroy-Semibold",
      fontWeight: 600,
      [breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
    subtitle2: {
      fontSize: 16,
      fontFamily: "Gilroy-Medium",

      [breakpoints.down("sm")]: {
        fontSize: "13px",
      },
      [breakpoints.between("sm", "md")]: {
        fontSize: "15px",
      },
    },
    body1: {
      fontSize: 17,
      fontFamily: "Gilroy-Medium",
      [breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
    body2: {
      fontSize: 16,
      fontFamily: "Gilroy-Medium",
      [breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
    button: {
      fontFamily: "Gilroy-Medium",
      textTransform: "capitalize",
      borderRadius: "2px",
      [breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
  },
});

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  flex: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: theme.palette.primary[theme.palette.type],
    color: theme.palette.primary.contrastText,
  },
  link: {
    color: theme.palette.text.secondary,
  },
});

const useStyles = makeStyles((theme) => ({
  loaderCenter: {
    position: "absolute",
    top: "50%",
    left: "50%",
    margin: "auto",
  },
  loaderOverlay: {
    zIndex: 10,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    position: "fixed",
    background: "rgba(0, 0, 0, 0.2)",
  },
}));

const App = (props) => {
  const classes = useStyles();
  const showLoader = (status) => {
    if (!status) {
      return "";
    }
    return (
      <div className={classes.loaderOverlay}>
        <div className={classes.loaderCenter}>
          <Loader style={{ transform: "scale(1.5)" }} type="line-scale" />
        </div>
      </div>
    );
  };
  return (
    <ThemeProvider theme={muiTheme}>
      <CssBaseline />
      <ToastContainer autoClose={2000} />
      <LoaderContextProvider showLoader={showLoader}>
        <Routes />
      </LoaderContextProvider>
    </ThemeProvider>
  );
};

export default withStyles(styles)(App);
