import React, { useState } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme } from '@material-ui/core/styles';
import App from './App';
import { Provider } from 'react-redux';
import store from './redux/store';

const Main = (props) => {
  const [nightMode, setNightMode] = useState(false);

  const createTheme = () => {
    return createMuiTheme({
      palette: {
        type: 'light',
      },
      typography: {
        useNextVariants: true,
      },
    });
  };

  const updateTheme = (mode) => {
    setNightMode(mode);
  };

  return (
    <Provider store={store}>
      <MuiThemeProvider theme={createTheme()}>
        <CssBaseline />
        <App {...props} updateTheme={updateTheme} nightMode={nightMode} />
      </MuiThemeProvider>
    </Provider>
  );
};

export default Main;
