let defaultTargetState = {
    labels: []
};

function LabelsReducer(state = defaultTargetState, action){
    switch(action.type){
        case 'SET_LABELS': 
            return action.payload;
        default: 
            return state;
    }
}

export default LabelsReducer;