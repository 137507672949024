import {
    decryptData, encryptData, getFormattedDate, getRequestedHeader,
} from '../../helper/commonHelper';
import { toast } from 'react-toastify';
import sendRequest from '../../httpRequest';

const todayDate = getFormattedDate(new Date());
const idb = window.indexedDB;

const requestFailure = (error) => {
    toast.error(error.message);
};

const actionDispatch = (actionType, dispatch, apiData) => {
    switch (actionType) {
    case 'GET_TARGETS':
        return dispatch({
            type: 'GET_TARGETS',
            payload: {
                targets: apiData,
            },
        });
    case 'ADD_INDUSTRY_VERTICAL':
        return dispatch({
            type: 'ADD_INDUSTRY_VERTICAL',
            payload: {
                industryVertical: apiData,
            },
        });
    case 'ADD_SERVICE_LINES':
        return dispatch({
            type: 'ADD_SERVICE_LINES',
            payload: {
                serviceLines: apiData,
            },
        });
    case 'GET_ALL_FAQS':
        return dispatch({
            type: 'GET_ALL_FAQS',
            payload: {
                allFaq: apiData,
            },
        });
    default:
        return null;
    }
};

const getDataFromApi = (dispatch, requestUrl, dataKeyName, actionType) => {
    sendRequest(
        'get',
        requestUrl,
        {},
        getRequestedHeader(),
        (res) => {
            let encryptedData; let apiData;
            if (res.data && res.data.results) {
                apiData = res.data.results;
                encryptedData = encryptData(res.data.results);
            } else {
                apiData = res.data;
                encryptedData = encryptData(res.data);
            }
            const targetResult = { name: dataKeyName, synedDate: todayDate, data: encryptedData };

            actionDispatch(actionType, dispatch, apiData);

            const dbPromise = idb.open('sw-database', 1);

            dbPromise.onupgradeneeded = (e) => {
                const db = dbPromise.result;
                if (!db.objectStoreNames.contains('dataStore')) {
                    db.createObjectStore('dataStore', {
                        keyPath: 'name',
                    });
                }
            };

            dbPromise.onsuccess = () => {
                const db = dbPromise.result;
                const tx = db.transaction('dataStore', 'readwrite');
                const userData = tx.objectStore('dataStore');
                const users = userData.put(targetResult);

                users.onsuccess = () => {
                    tx.oncomplete = () => {
                        db.close();
                    };
                };

                users.onerror = (query) => {
                    db.close();
                    console.log('add error', query);
                };

                tx.onerror = (query) => {
                    console.log('error', query);
                    db.close();
                };
            };
            dbPromise.onerror = (event) => {
                console.log('event', event);
            };
        },
        requestFailure,
    );
};

const getIndexedDBData = (dispatch, dataKeyName, actionType, requestUrl) => {
    const dbPromise = idb.open('sw-database', 1);

    dbPromise.onupgradeneeded = (e) => {
        const db = dbPromise.result;
        if (!db.objectStoreNames.contains('dataStore')) {
            db.createObjectStore('dataStore', {
                keyPath: 'name',
            });
        }
    };

    dbPromise.onsuccess = () => {
        const db = dbPromise.result;
        const tx = db.transaction('dataStore', 'readonly');
        const userData = tx.objectStore('dataStore');
        const users = userData.get(dataKeyName);

        users.onsuccess = (query) => {
            const resData = query.srcElement.result;
            if (resData && resData.synedDate && resData.synedDate === todayDate && resData.data) {
                const temp = decryptData(resData.data);
                actionDispatch(actionType, dispatch, temp);
            } else {
                getDataFromApi(dispatch, requestUrl, dataKeyName, actionType);
            }
        };

        users.onerror = (query) => {
            getDataFromApi(dispatch, requestUrl, dataKeyName, actionType);
            console.log('error occures while getting data');
        };

        tx.onerror = (query) => {
            console.log('error', query);
            db.close();
        };
        tx.oncomplete = () => {
            db.close();
        };
    };

    dbPromise.onerror = (event) => {
        getDataFromApi(dispatch, requestUrl, dataKeyName, actionType);
        console.log('event', event);
    };
};

export default getIndexedDBData;
