import { DOUGHNUT_CHART_CONFIG } from '../common/charts/DefaultConfig';
import { RISK_CATEGORY_COLORS } from '../common/constants';
import { getRequestedHeader } from '../../helper/commonHelper';
import DoughnutChart from '../common/charts/DoughnutView';
import Loader from '../common/LoaderView';
import React, { useEffect, useState } from 'react';
import sendRequest from '../../httpRequest';

/**
 * Function to convert data specific to chart format
 * @param {Objcet} rawData - the data that is to be converted
 * @param {String} chartType
 */
const convertChartSpecificData = (rawData, chartType) => {
    if (chartType === 'doughnut') {
        let data = [];
        data = [{
            label: 'Low',
            value: rawData.low.split('%')[0],
            color: RISK_CATEGORY_COLORS.LOW_RISK,
            labelFontColor: '#ffffff',
        }, {
            label: 'Moderate',
            value: rawData.moderate.split('%')[0],
            color: RISK_CATEGORY_COLORS.MODERATE_RISK,
            labelFontColor: '#ffffff',
        }, {
            label: 'High',
            value: rawData.high.split('%')[0],
            color: RISK_CATEGORY_COLORS.HIGH_RISK,
            labelFontColor: '#ffffff',
        }, {
            label: 'Critical',
            value: rawData.critical.split('%')[0],
            color: RISK_CATEGORY_COLORS.CRITICAL_RISK,
            labelFontColor: '#ffffff',
        }];

        return data;
    } if (chartType === 'column') {
        const data = [];
        data.categories = [{
            category: [{
                label: 'Min Rating',
            }, {
                label: 'Average Rating',
            }, {
                label: 'Max Rating',
            }],
        }];

        data.dataset = [{
            seriesname: 'Supply Wisdom',
            data: [{
                value: rawData.min_rating,
            }, {
                value: rawData.average_rating,
            }, {
                value: rawData.max_rating,
            }],
        }, {
            seriesname: 'Your Portfolio',
            data: [{
                value: rawData.my_min_rating,
            }, {
                value: rawData.my_average_rating,
            }, {
                value: rawData.my_max_rating,
            }],
        }];

        return data;
    }
};

const ScoreFromFindings = (props) => {
    const [allScore, setAllScore] = useState({});
    const [loading, setLodingStatus] = useState(false);

    const { raasAccountSelected } = props;

    useEffect(() => {
        setLodingStatus(true);
        if (props && props.targetId && props.reportId) {
            const requestData = {
                target_type: props.targetId,
                report_type: props.reportId,
                quarter: props.quarter,
                year: props.year,
                label: props.label,
                client_org: raasAccountSelected.id,
            };

            sendRequest('get', 'api/v2/risk/snapshot-score/findings/', requestData, getRequestedHeader(), allScoreRequestSuccess, requestFailure);
        }
    }, [props.targetId, props.reportId, props.quarter, props.year, props.label, raasAccountSelected]);

    /**
     * Function to set all score from response data
     * @param {Object} response
     */
    const allScoreRequestSuccess = (response) => {
        setLodingStatus(false);
        setAllScore(response.data);
    };

    /**
     * Function to handle request failure
     * @param {Object} error
     */
    const requestFailure = (error) => {
        setLodingStatus(false);
        console.log(error);
    };

    let allScoreView = null; let
        chartConfig = {};

    chartConfig = DOUGHNUT_CHART_CONFIG;
    chartConfig.dataSource.chart.showPercentValues = 1;
    if (Object.keys(allScore).length) {
        const chartData = convertChartSpecificData(allScore, 'doughnut');
        allScoreView = (
            <DoughnutChart
                chartData={chartData}
                chartConfig={chartConfig}
                centerLabel={`<b>${allScore.targets_count}</b><br>Targets`}
            />
        );
    }
    return (loading ? <Loader loading={loading} /> : allScoreView);
};

export default ScoreFromFindings;
