function setParameter(parameterData) {
    return {
        type: 'SET_PARAMETER',
        payload: {
            parameterData,
        },
    };
}

function setTargetData(targetData) {
    return {
        type: 'SET_TARGET_DATA',
        payload: {
            targetData,
        },
    };
}

function setTargetSortedCategories(sortedCategories) {
    return {
        type: 'SET_SORTED_CATEGORIES',
        payload: {
            sortedCategories,
        },
    };
}

export {
    setParameter, setTargetData,setTargetSortedCategories,
};
