import React, { createContext } from 'react';

export const Context = createContext({});
export const Provider = ({ children, showLoader }) => {
    const loaderContext = {
        showLoader,
    };

    // pass the value in provider and return
    return <Context.Provider value={loaderContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;
