import { useSelector } from 'react-redux';
import AskAnAnalyst from '../components/common/AskAnAnalyst';
import React from 'react';
import setAnalystTargets from '../redux/actions/askAnalystAction';

/**
 * This component acts as a container of the Login component.
 * @param {Object} props
 */
const AskAnalystContainerView = (props) => {
    const stateProps = {
        analystTargets: useSelector((state) => state.AnalystTargetsReducer.analystTargets),
    };
    const actionProps = { setAnalystTargets };
    return <AskAnAnalyst actionProps={actionProps} stateProps={stateProps} />;
};

export default AskAnalystContainerView;
