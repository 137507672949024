import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
import DashboardContainer from '../containers/DashboardContainerView';
import ProtectedRoute from './ProtectRoute';
import PublicRoute from './PublicRoute';
import FAQ from '../components/FAQ';
import ExecutiveOne from '../components/embedLinks/ExecutiveOne';
import ExecutiveTwo from '../components/embedLinks/ExecutiveTwo';
import AnalystTargets from '../components/embedLinks/AnalystTargets';
import AnalystLocations from '../components/embedLinks/AnalystLocations';
import Administrator from '../components/embedLinks/Administrator';
import PrimaryAlertDashboard from '../components/embedLinks/PrimaryAlertDashboard';
import PrimaryThirdPartyDashboard from '../components/embedLinks/PrimaryThirdPartyDashboard';
import Administration from '../components/embedLinks/Administration';
import ExumaDetails from '../components/ExumaDetails';
import WorkatoIntegration from '../components/organization/WorkatoIntegration';
import Loader from '../components/common/LoaderView';
import LocationAnalyticsDashboard from '../components/embedLinks/LocationAnalyticsDashboard';
import { toast } from 'react-toastify';

export function lazyPreload(component) {
  const Component = lazy(component);
  Component.preload = component;
  return Component;
}

const AppRouter = () => (
  <Router>
    <Suspense fallback={(<Loader />)}>
      <Switch>
        <ProtectedRoute path='/organization' component={lazyPreload(() => import('../containers/OrganizationContainer'))} />
        <ProtectedRoute path='/addEditBanding' component={lazyPreload(() => import('../components/organization/BuildRiskMetrics/AddEditBandings'))} />
        <ProtectedRoute path='/labels' component={lazyPreload(() => import('../containers/LabelsContainer'))} />
        <ProtectedRoute path='/settings' component={lazyPreload(() => import('../containers/SettingsContainer'))} />
        <ProtectedRoute path='/dashboard' component={lazyPreload(() => import('../containers/DashboardContainerView'))} />
        <ProtectedRoute path="/targetList" component={lazyPreload(() => import('../containers/TargetContainerView'))} />
        <ProtectedRoute path='/targetProfile' component={lazyPreload(() => import('../containers/TargetProfileContainerView'))} />
        <ProtectedRoute path='/alertTargetList' component={lazyPreload(() => import('../containers/AlertTargetListViewContainer'))} />
        <ProtectedRoute path='/alertProfile' component={lazyPreload(() => import('../containers/AlertProfileViewContainer'))} />
        <ProtectedRoute path='/KnowledgeManagement' component={lazyPreload(() => import('../components/KnowledgeManagement'))} />
        <ProtectedRoute path='/alertDashboard' component={lazyPreload(() => import('../containers/AlertDashboardContainer'))} />
        <ProtectedRoute path='/alertList' component={lazyPreload(() => import('../containers/AlertListContainer'))} />
        <ProtectedRoute path='/articleDetails' component={lazyPreload(() => import('../components/KnowledgeManagementDetail'))} />
        <ProtectedRoute path='/FAQ' component={FAQ} />
        <ProtectedRoute path='/VideoTutorials' component={lazyPreload(() => import('../components/VideoTutorials'))} />
        <ProtectedRoute path='/ExecutiveOne' component={ExecutiveOne} />
        <ProtectedRoute path='/ExecutiveTwo' component={ExecutiveTwo} />
        <ProtectedRoute path='/Administrator' component={Administrator} />
        <ProtectedRoute path='/PrimaryAlertDashboard' component={PrimaryAlertDashboard} />
        <ProtectedRoute path='/PrimaryThirdPartyDashboard' component={PrimaryThirdPartyDashboard} />
        <ProtectedRoute path='/LocationAnalyticsDashboard' component={LocationAnalyticsDashboard} />
        <ProtectedRoute path='/Administration' component={Administration} />
        <ProtectedRoute path='/WorkatoIntegration' component={WorkatoIntegration} />
        <ProtectedRoute path='/AnalystTargets' component={AnalystTargets} />
        <ProtectedRoute path='/AnalystLocations' component={AnalystLocations} />
        <ProtectedRoute path='/exumaDetails' component={ExumaDetails} />
        <PublicRoute path="/login" component={lazyPreload(() => import('../containers/LoginContainerView'))} />
        <PublicRoute path="/forgetPassword" component={lazyPreload(() => import('../components/ForgetPasswordView'))} />
        <PublicRoute path="/reset_form_cofirm" component={lazyPreload(() => import('../components/resetPassword'))} />
        <PublicRoute path="/signup" component={lazyPreload(() => import('../components/SignupView'))} />
        <Redirect exact from="/" to="/login" component={DashboardContainer} />
        <ProtectedRoute path='*' />
      </Switch>
    </Suspense>
  </Router>
)

export default AppRouter;
