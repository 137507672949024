import { RISK_CATEGORY_COLORS, RISK_CATEGORY_RANGE } from '../constants';

const DOUGHNUT_CHART_CONFIG = {
    type: 'doughnut2d',
    width: '100%',
    height: 400,
    dataFormat: 'json',
    dataSource: {
        chart: {
            chartBottomMargin: '0',
            chartTopMargin: '0',
            chartLeftMargin: '0',
            chartRightMargin: '0',
            doughnutRadius: 70,
            showLabels: 1,
            showValues: 1,
            decimals: 1,
            theme: 'fusion',
            showPlotBorder: 1,
            plotBorderThickness: 2,
            showLegend: '0',
            labelFont: 'Gilroy-Semibold',
            centerLabelFont: 'Gilroy-Semibold',
            centerLabelFontSize: '16',
            labelFontSize: '14',
            showZeroPies: '0',
            centerLabelBold: 0,
            startingAngle: 180,
            minAngleForLabel: 15,
            minAngleForValue: 15,
            showPercentInToolTip: 1,
            showPercentValues: 0,
            labelSepChar: ' ',
        },
    },
};

const COLOR_RANGE_FIRST_LEVEL = {
    minvalue: '-1',
    mapByPercent: '1',
    color: [{
        code: RISK_CATEGORY_COLORS.LOW_RISK,
        maxvalue: RISK_CATEGORY_RANGE.LOW_RISK_LIMIT,
    }, {
        code: RISK_CATEGORY_COLORS.MODERATE_RISK,
        maxvalue: RISK_CATEGORY_RANGE.MODERATE_RISK_LIMIT,
    }, {
        code: RISK_CATEGORY_COLORS.HIGH_RISK,
        maxvalue: RISK_CATEGORY_RANGE.HIGH_RISK_LIMIT,
    }, {
        code: RISK_CATEGORY_COLORS.CRITICAL_RISK,
        maxvalue: RISK_CATEGORY_RANGE.CRITICAL_RISK_LIMIT,
    }, {
        code: RISK_CATEGORY_COLORS.INFO_RISK,
        maxvalue: RISK_CATEGORY_RANGE.INFO_RISK_LIMIT,
    }],
};

export {
    DOUGHNUT_CHART_CONFIG,
    COLOR_RANGE_FIRST_LEVEL,
};
