import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import { localStorageGetItem } from './helper/commonHelper';
import Main from './Main';
import React from 'react';
import ReactDOM from 'react-dom';

document.getElementById('preFind').remove();
ReactDOM.render(<Main />, document.getElementById('root'));

window.addEventListener('beforeunload', () => {
    if (!localStorageGetItem('keepMeSignedIn')) {
        localStorage.clear();
    }

    return '';
});
