const errorTypes = [{
    errorCode: 1,
    errorMessage: 'This field is mandatory.',
}, {
    errorCode: 2,
    errorMessage: 'The Email address is mandatory.',
    fieldName: 'email',
}, {
    errorCode: 3,
    errorMessage: 'The Email address is incorrect.',
    fieldName: 'email',
}, {
    errorCode: 4,
    errorMessage: 'The Password field is mandatory.',
    fieldName: 'password',
}, {
    errorCode: 5,
    errorMessage: 'Password should contain at least one lowercase, one uppercase, one number and one symbol. Minimum length should be 8.',
    fieldName: 'password',
}, {
    errorCode: 6,
    errorMessage: 'Please enter a valid phone number.',
    fieldName: 'phoneNumber',
}, {
    errorCode: 7,
    errorMessage: 'Phone Number is mandatory.',
    fieldName: 'phoneNumber',
}, {
    errorCode: 8,
    errorMessage: 'Some error occured.',
}, {
    errorCode: 9,
    errorMessage: 'Network error occured.',
}, {
    errorCode: 10,
    errorMessage: 'Email sent successfully.',
}, {
    errorCode: 11,
    errorMessage: "Email doesn't exists.",
}, {
    errorCode: 12,
    errorMessage: 'Fill all required fields.',
}, {
    errorCode: 13,
    errorMessage: "New Password doesn't match Confirm Password.",
},
{
    errorCode: 14,
    errorMessage: 'The Domain is not allowed.',
}];

export default errorTypes;
