import { DASHBOARD_IDS } from '../common/constants';
import { getDynamicDashbaordLink } from './common';
import { getParameterByName } from '../../helper/commonHelper';
import EmbedView from './EmbedView';
import React, { useEffect, useState } from 'react';

const Administration = () => {
    const [link, setLink] = useState();
    useEffect(() => {
        const { dashboard_id } = getParameterByName('dashboard_id');
        const request = {
            dashboard_id: dashboard_id || DASHBOARD_IDS.ADMINISTRATION,
        };
        getDynamicDashbaordLink(request, (src) => {
            setLink(src);
        });
    }, []);
    //const link = "https://supplywisdom.sisense.com/app/main/dashboards/63c0e5b73e387a0035c5214e?embed=true&l=true&t=true&h=true";
    return (
        <EmbedView link={link} />
    );
};

export default Administration;
