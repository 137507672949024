import { loginUserDetail } from '../redux/actions/loginAction';
import { setRaasChampion, updateRaasAccountSelected, updateReportId } from '../redux/actions/navbarAction';
import { useSelector } from 'react-redux';
import NavBar from '../components/common/NavbarView';
import React from 'react';

import updateRaasOrganization from '../redux/actions/raasOrgAction';

/**
 * This component acts as a container of the Navbar component.
 * @param {Object} props
 */
const NavbarContainer = (props) => {
    const actionProps = {
        updateRaasOrganization, updateReportId, updateRaasAccountSelected, loginUserDetail, setRaasChampion
    };
    const loginUserData = useSelector((state) => state.LoginReducer.loginDetail);
    const isRaasChampion = useSelector((state) => state.NavbarReducer.isRaasChampion);
    const reportId = useSelector((state) => state.NavbarReducer.reportIdSelected);
    const raasSelected = useSelector((state) => state.NavbarReducer.raasAccountSelected);
    const stateProps = { loginUserData, isRaasChampion, reportId, raasSelected };

    return <NavBar {...props} actionProps={actionProps} stateProps={stateProps} />;
};

export default NavbarContainer;
