import { Box, Tooltip } from '@material-ui/core';
import { COLOR_RANGE_FIRST_LEVEL } from '../common/charts/DefaultConfig';
import { RISK_CATEGORY_COLORS, RISK_CATEGORY_RANGE } from '../common/constants';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import BreadCrumb from '../common/BreadCrumbView';
import InfoIcon from '@material-ui/icons/Info';
import NoDataElement from '../common/NoDataElement';
import React, { useEffect, useState } from 'react';
import TargetLists from '../common/listView/TargetsListView';
import TreemapChart from '../common/charts/TreemapView';

const useStyles = makeStyles((theme) => ({
    riskContainer: {
        marginTop: '10px',
    },
    headContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    targetCount: {
        fontFamily: 'Gilroy-Semibold',
    },
    fontSetPortImgg: {
        verticalAlign: 'inherit',
        fontSize: '14px',
        color: '#3c3b3b',
    },
    tableMiddleSecImg: {
        verticalAlign: 'middle',
    },
    routeCont: {
        display: 'flex',
    },
    tooltip: {
        marginLeft: '3px',
    },
}));

const CustomizedTooltip = withStyles(() => ({
    tooltip: {
        maxWidth: 220,
        fontSize: '14px',
        border: '1px solid #dadde9',
    },
}))(Tooltip);

/**
 * Function to convert data to chart specific format
 * @param {Object} rawData
 * @param {String} chartType
 * @param {Number} level - The drill down level of chart
 */
const convertChartSpecificData = (rawData, chartType, level) => {
    if (chartType === 'treemap' && level === 1) {
        // For first level of drill-down
        const data = [{}];
        if (rawData) {
            data[0].value = rawData.highcount + rawData.moderatecount + rawData.criticalcount;
            data[0].data = [{
                label: `${rawData.highcount}<br>High`,
                value: rawData.highcount,
                sValue: RISK_CATEGORY_RANGE.HIGH_RISK_LIMIT,
            }, {
                label: `${rawData.criticalcount}<br>Critical`,
                value: rawData.criticalcount,
                sValue: RISK_CATEGORY_RANGE.CRITICAL_RISK_LIMIT,
            }, {
                label: `${rawData.moderatecount}<br>Moderate`,
                value: rawData.moderatecount,
                sValue: RISK_CATEGORY_RANGE.MODERATE_RISK_LIMIT,
            }];
        }
        return data;
    } if (chartType === 'treemap' && level === 2) {
        // For second level of drill-down
        const chartData = {
            colorRange: {
                minvalue: '0',
                code: '#ffffff',
                mapByPercent: '1',
                gradient: '1',
                color: [{
                }],
            },
            data: [{
                value: 0,
                data: [],
            }],
        };
        if (rawData) {
            rawData.sub_categories.map((item, index) => {
                chartData.data[0].value += item.targets.length;
                chartData.data[0].data.push({
                    label: `${item.name}<br>${item.targets.length} Targets`,
                    value: item.targets.length,
                    svalue: item.targets.length,
                    fontColor: '#000000',
                });
            });

            // chartData.data[0].value = rawData.sub_categories.length;
            chartData.colorRange.color[0].maxvalue = chartData.data[0].value;
        }
        return chartData;
    }
};

const RiskCategory = (props) => {
    const classes = useStyles();

    // State maintained for the level of drill-down
    const [drillDownLevel, setDrillDownLevel] = useState({
        index: 0,
        parentDataIndex: '',
        secondaryDataIndex: '',
    });

    useEffect(() => {
        setDrillDownLevel({
            index: 0,
            parentDataIndex: '',
            secondaryDataIndex: '',
        });
    }, [props.parentRisk]);

    /**
     * Function to handle drill down from level 1 and 2
     * @param {Object} e
     */
    const handleDataPlotClick = (e) => {
        e.stopImmediatePropagation();
        const currentLevel = (drillDownLevel.index + 1) % 3;
        let parentDataIndex;
        let secondaryDataIndex;
        if (drillDownLevel.index === 0) {
            parentDataIndex = e.data.name.split('<br>')[1].split(' ')[0];
            setDrillDownLevel({
                ...drillDownLevel,
                index: currentLevel,
                parentDataIndex,
            });
        } else if (drillDownLevel.index === 1) {
            secondaryDataIndex = e.data.name.split('<br>')[0];
            setDrillDownLevel({
                ...drillDownLevel,
                index: currentLevel,
                secondaryDataIndex,
            });
        }
    };

    /**
     * Function to handle risk route click
     */
    const handleRouteClick = (levelIndex) => {
        setDrillDownLevel({
            ...drillDownLevel,
            index: levelIndex,
        });
    };

    let firstLevelChart;
    let secondLevelChart;
    let thirdLevelList;
    let levelRoutes = [];
    let chartData;
    const { parentRisk, subCategoryRisks, parentRiskId } = props;
    let colorKey;
    // Create view/route for level one
    if (drillDownLevel.index === 0) {
        chartData = convertChartSpecificData(parentRisk, 'treemap', 1);
        if (parentRisk) {
            levelRoutes.push(parentRisk.name);
        }

        if (chartData[0].value) {
            firstLevelChart = (
                <TreemapChart
                    data={chartData}
                    colorrange={COLOR_RANGE_FIRST_LEVEL}
                    plotClickMethod={handleDataPlotClick}
                />
            );
        } else {
            firstLevelChart = <NoDataElement content="No Data to Display" />;
        }
    } // Create view/route/target count element for level two
    else if (drillDownLevel.index === 1) {
        const drillDownParent = drillDownLevel.parentDataIndex.toLowerCase();
        const sub_category = subCategoryRisks && subCategoryRisks.find((risk, index) => risk.name === parentRisk.name);
        chartData = convertChartSpecificData(sub_category[drillDownParent], 'treemap', 2);
        colorKey = drillDownParent === 'medium' ? 'MODERATE_RISK' : `${(drillDownParent).toUpperCase()}_RISK`;
        // Set the max color code
        chartData.colorRange.color[0].code = RISK_CATEGORY_COLORS[colorKey];
        levelRoutes.push(parentRisk.name);
        levelRoutes.push(`${drillDownLevel.parentDataIndex} Risk`);
        levelRoutes.push();
        secondLevelChart = (
            <TreemapChart
                data={chartData.data}
                colorrange={chartData.colorRange}
                plotClickMethod={handleDataPlotClick}
            />
        );
    } // Create view/route/traget count for third level
    else {
        levelRoutes = [parentRisk.name,
            `${drillDownLevel.parentDataIndex} Risk`,
            drillDownLevel.secondaryDataIndex,
        ];
        const sub_category = subCategoryRisks && subCategoryRisks.find((risk, index) => risk.name === parentRisk.name);
        const subCategoryData = sub_category[drillDownLevel.parentDataIndex.toLowerCase()];
        const listData = subCategoryData.sub_categories.find((item, index) => (item.name === drillDownLevel.secondaryDataIndex));
        thirdLevelList = (
            <TargetLists
                listData={listData}
                heading={drillDownLevel.secondaryDataIndex}
                parentRisk={drillDownLevel.parentDataIndex}
                categoryId={parentRiskId}
                subCategoryId={listData.id}
            />
        );
    }

    return (
        <div className={classes.riskContainer}>
            <Box className={classes.routeCont}>
                <Box className={classes.headContainer}>
                    <BreadCrumb routes={levelRoutes} handleClick={handleRouteClick} currentLevel={drillDownLevel.index} />
                </Box>
                {
                    parentRisk.description && (drillDownLevel.index === 0) && (
                        <Box className={classes.tableMiddleSecImg}>
                            {' '}
                            <CustomizedTooltip title={parentRisk.description} arrow className={classes.tooltip}>
                                <InfoIcon className={classes.fontSetPortImgg} />
                            </CustomizedTooltip>
                            {' '}
                        </Box>
                    )
                }
            </Box>
            {drillDownLevel.index === 0 ? firstLevelChart : null}
            {drillDownLevel.index === 1 ? secondLevelChart : null}
            {drillDownLevel.index === 2 ? thirdLevelList : null}
        </div>
    );
};

export default RiskCategory;
