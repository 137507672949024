import { validateInput } from '../../common/validationFunction';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const inputBoxStyle = (theme) => ({

    firstHalf: {
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    secondHalf: {
        width: '50%',
        marginLeft: '20px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginLeft: '0px',
            marginTop: '45px',
        },
    },
    alignCenter: {
        textAlign: 'left',
    },
});

const InputTextField = forwardRef((props, ref) => {
    const [error, setError] = useState(null);
    const { classes } = props;
    let errorHTML = null;

    /**
     * On Change event of the textbox.
     * @param  {Object} event [Event object]
     */
    const onChange = (event) => {
        props.onChange(event);
    };

    /**
     * Validates field on blur.
     */
    const validateField = () => {
        const {
            validationType, value, name,
        } = props;

        if (validationType) {
            const error = validateInput(validationType, value);
            setError(error);
            props.setErrorValue(`${name}`, (error && error.errorMessage) || '');
        }
    };

    /**
     * [Reset Errors]
     */
    const resetErrorCode = () => {
        setError(null);
        const { name } = props;

        if (props.setErrorValue) {
            props.setErrorValue(`${name}`);
        }
    };

    useImperativeHandle(ref, () => ({
        validateField,
        resetErrorCode,
    }));

    if (error || props.errorMessage) {
        errorHTML = (
            <Grid item md={12} lg={12} className={classes.alignCenter}>
                <Typography color="error" variant="subtitle2">{error ? error.errorMessage : props.errorMessage}</Typography>
            </Grid>
        );
    }

    return (
        <React.Fragment>
            <div>
                <TextField
                    className={props.className}
                    fullWidth
                    multiline={props.multiline}
                    type={props.type}
                    placeholder={props.placeholder}
                    variant="outlined"
                    id={props.id}
                    name={props.name}
                    value={props.value}
                    onChange={onChange}
                    onBlur={validateField}
                    onFocus={resetErrorCode}
                    onKeyPress={props.onKeyPress}
                    disabled={!!props.disabled}
                    inputProps={props.maxLength ? { maxLength: props.maxLength } : {}}
                />
                {errorHTML}
            </div>

        </React.Fragment>
    );
});

InputTextField.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string.isRequired,
    id: PropTypes.string,
    label: PropTypes.string,
    multiline: PropTypes.bool,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.string,
    validationType: PropTypes.oneOf([
        '', 'mandatory', 'email', 'password', 'phone',
    ]),
    onChange: PropTypes.func,
    setErrorValue: PropTypes.func,
    classes: PropTypes.object.isRequired,
};

InputTextField.defaultProps = {
    type: 'text',
    value: '',
    validationType: '',
};

export default withStyles(inputBoxStyle)(InputTextField);
