import { Box, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CloseIcon from '@material-ui/icons/Close';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import React from 'react';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const breakpoints = createBreakpoints({});

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        justifyContent: 'center',
        [breakpoints.down('xs')]: {
            zIndex: '99999 !important',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            zIndex: '99999 !important',
        },
    },
    paper: {
        margin: '50px',
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
        width: '60%',
        maxHeight: '90vh',
        height: 'fit-content',
        boxShadow: theme.shadows[5],
        [breakpoints.down('xs')]: {
            height: '100vh',
            width: '100%',
            margin: '0px',
            maxHeight: '100vh',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            width: '100%',
            margin: '0px',
            maxHeight: '100vh',
        },
        '&:focus': {
            outline: 'none',
        },
    },
    modalBody: {
        padding: theme.spacing(2, 4, 3),
        [breakpoints.down('xs')]: {
            padding: '12px',
        },
    },
    modalHead: {
        padding: '10px 15px 10px',
        display: 'flex',
        justifyContent: 'space-between',
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
        borderBottom: '1px solid #ededed',
        zIndex: 5,
        [breakpoints.down('xs')]: {
            zIndex: '99',
        },
    },
    closeBtn: {
        float: 'right',
        cursor: 'pointer',
    },
}));

const CustomModal = (props) => {
    const {
        modalData, open, handleClose, title,
    } = props;
    const classes = useStyles();
    return modalData ? (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Box className={classes.paper}>
                    <Box className={classes.modalHead}>
                        <Typography variant="h5">{title}</Typography>
                        <Box component="span" className={classes.closeBtn}>
                            <CloseIcon onClick={handleClose} />
                        </Box>
                    </Box>
                    <Box className={classes.modalBody}>
                        {modalData}
                    </Box>
                </Box>
            </Fade>
        </Modal>
    ) : null;
};

export default CustomModal;
