let defaultLoginState = {
    organizations: []
};

function RaasOrganizationReducer(state = defaultLoginState, action){
    switch(action.type){
        case 'ADD_RAAS_ORGANIZATIONS': 
            return action.payload;
        default: 
            return state;
    }
}

export default RaasOrganizationReducer;