function updateAccessToken(accessToken) {
    return {
        type: 'ADD_ACCESS_TOKEN',
        payload: {
            accessToken
        }
    }
}

function loginUserDetail(loginDetail) {
    return {
        type: 'ADD_LOGIN_USER_DETAIL',
        payload: {
            loginDetail
        }
    }
}

export { updateAccessToken, loginUserDetail };