function setTargetTypes(targetTypes) {
    return {
        type: 'SET_TARGET_TYPES',
        payload: {
            targetTypes,
        },
    };
}

export default setTargetTypes;
