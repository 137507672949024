import { METHOD_CONF, URL_CONF } from '../../common/Constants';
import { getRequestedHeader } from '../../helper/commonHelper';
import sendRequest from '../../httpRequest';

const getDynamicDashbaordLink = (request, callback) => {
    sendRequest(
        METHOD_CONF.GET,
        URL_CONF.GET_SISENSE_URL,
        request,
        getRequestedHeader(),
        (res) => {
            if (res.data && res.data.url) {
                callback(res.data.url);
            } else {
                callback('');
            }
        },
        () => callback('')
    );
};

export {
    getDynamicDashbaordLink,
};
