import { Box } from '@material-ui/core';
import { getParameterByName } from '../../helper/commonHelper';
import React from 'react';

const WorkatoIntegration = () => {
    const { id, token } = getParameterByName('id', 'token');

    return (
        <Box>
            <iframe
                src={`https://www.workato.com/direct_link/embedded/connections/${id}?workato_dl_token=${token}`}
                width="100%"
                style={{ height: '100vh', padding: '20px 60px' }}
                title="Workato Integration"
            />
        </Box>
    );
};

export default WorkatoIntegration;
