const defaultTargetState = {
    parameterData: [],
    targetData: [],
    sortedCategories: [],
};

function TargetReducer(state = defaultTargetState, action) {
    switch (action.type) {
    case 'SET_PARAMETER':
        return {
            ...state,
            parameterData: action.payload.parameterData,
        };
    case 'SET_TARGET_DATA':
        return {
            ...state,
            targetData: action.payload.targetData,
        };
    case 'SET_SORTED_CATEGORIES':
        return {
            ...state,
            sortedCategories: action.payload.sortedCategories,
        };
    default:
        return state;
    }
}

export default TargetReducer;
