
/**
 * Function to create a custom tooltip component in charts
 * @param {String} currentCategory
 * @param {Object} portfolioData
 * @param {Boolean} isRaasChampion
 * @param {Object} evtObj
 */
const customTooltip = (currentCategory, portfolioData, isRaasChampion, targetType, reportType, evtObj) => {
    const dataset = evtObj.data.datasetIndex;
    const { dataIndex } = evtObj.data;

    // Add tooltip for your portfolio only
    if (dataset !== 0 && dataIndex !== 1) {
        let target_name; let score; let
            link;
        if (isRaasChampion) {
            // set organization
            const org = (dataset === 1) ? 'org_1' : 'org_2';
            switch (dataIndex) {
            case 0: target_name = portfolioData[org][0].min_target;
                score = portfolioData[org][0].min_rating;
                link = `/targetProfile?id=${portfolioData[org][0].my_min_target_id}`;
                break;
            case 2: target_name = portfolioData[org][0].max_target;
                score = portfolioData[org][0].max_rating;
                link = `/targetProfile?id=${portfolioData[org][0].my_max_target_id}`;
                break;
            default: break;
            }
        } else {
            const tooltipData = (currentCategory === 'Composite Rating') ? portfolioData.report_type[0] : portfolioData;
            const minTarget = tooltipData.my_min_target;
            const minScore = tooltipData.my_min_rating;
            const maxTarget = tooltipData.my_max_target;
            const maxScore = tooltipData.my_max_rating;
            const minTargetId = tooltipData.my_min_target_id;
            const maxTargetId = tooltipData.my_max_target_id;

            switch (dataIndex) {
            case 0: target_name = minTarget;
                score = minScore;
                link = `/targetProfile?id=${minTargetId}`;
                break;
            case 2: target_name = maxTarget;
                score = maxScore;
                link = `/targetProfile?id=${maxTargetId}`;
                break;
            default: break;
            }
        }
        // Get the annotations object from fusioncharts object
        const annotations = evtObj && evtObj.sender.annotations;
        annotations.show('CalloutBase');
        annotations.show('CalloutTriangle');
        annotations.show('CalloutLabel1');
        annotations.show('CalloutLabel2');
        annotations && annotations.update('CalloutBase', {
            x: ['$dataset', dataset, 'set', dataIndex, 'x-40'].join('.'),
            y: ['$dataset', dataset, 'set', dataIndex, 'starty-5'].join('.'),
            tox: ['$dataset', dataset, 'set', dataIndex, 'x+40'].join('.'),
            toy: ['$dataset', dataset, 'set', dataIndex, 'starty-50'].join('.'),
        });
        annotations && annotations.update('CalloutTriangle', {
            x: ['$dataset', dataset, 'set', dataIndex, 'x'].join('.'),
            y: ['$dataset', dataset, 'set', dataIndex, 'starty-8'].join('.'),
        });
        annotations && annotations.update('CalloutLabel1', {
            text: target_name || 'undefined',
            x: ['$dataset', dataset, 'set', dataIndex, 'x'].join('.'),
            y: ['$dataset', dataset, 'set', dataIndex, 'starty-38'].join('.'),
            color: '##428BCA',
            link,
        });
        annotations && annotations.update('CalloutLabel2', {
            text: score && score.toFixed(2),
            x: ['$dataset', dataset, 'set', dataIndex, 'x'].join('.'),
            y: ['$dataset', dataset, 'set', dataIndex, 'starty-18'].join('.'),
            color: '#000000',
        });
    }
};

export default customTooltip;
