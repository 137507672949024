import "leaflet/dist/leaflet.css";
import { Marker, Popup } from "react-leaflet";
import {
  RISK_CATEGORY_COLORS,
  RISK_CATEGORY_RANGE,
  selectMarkerIcon,
} from "../common/constants";
import { Typography } from "@material-ui/core";
import { getRequestedHeader } from "../../helper/commonHelper";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import NoDataElement from "../common/NoDataElement";
import React, { Fragment, useEffect, useState, useRef } from "react";
import WorldmapView from "../common/charts/WorldmapView";
import sendRequest from "../../httpRequest";

const useStyles = makeStyles(() => ({
  popupMap: {
    "& a": {
      backgroundColor: "#428BCA !important",
      color: "#fff !important",
      borderRadius: "50% !important",
      right: "-10px !important",
      top: "-9px !important",
    },
    "& .leaflet-popup-content": {
      margin: "5px 7px",
      width: "200px !important",
    },
    "& p": {
      margin: "unset",
    },
    "& .leaflet-popup-tip-container": {
      display: "none",
    },
  },
  popupTable: {
    marginTop: "5px",
  },
  popupText: {
    color: "#FF691F",
    cursor: "pointer",
    padding: "2px 5px",
  },
}));

const MapViewNP = (props) => {
  const classes = useStyles();
  const [countries, setCountries] = useState([]);
  const [cityData, setCityData] = useState([]);
  const { modifyNPData, targetIdList } = props;
  const history = useHistory();
  const center = [50, 20];
  const ZOOM_LEVEL = 1;
  const countryStyle = {
    fillColor: "#fff",
    fillOpacity: 1,
    color: "#000",
    weight: 0.4,
  };

  /**
   * Function to handle API call failure
   * @param {Object} error
   */
  const requestFailure = (error) => {
    console.log(error);
  };

  /**
   * Function to handle latLang API call success
   * @param {Array} success
   */
  const latLangSuccess = (res) => {
    const modData = [];
    res.data &&
      res.data.length &&
      res.data.forEach((latLongDet) => {
        const selected = modifyNPData.find((np) => np.id === latLongDet.id);
        if (selected) {
          const temp = {
            name: selected.name,
            id: selected.id,
            rating: selected.rating,
            targets: selected.targets,
            lattitude: latLongDet.lattitude,
            longitude: latLongDet.longitude,
          };
          modData.push(temp);
        }
      });
    setCityData(modData);
  };
  useEffect(() => {
    if (targetIdList && targetIdList.length) {
      const uniqueIds = targetIdList.filter(
        (c, index) => targetIdList.indexOf(c) === index
      );
      const data = { target_ids: uniqueIds };
      if (uniqueIds.length) {
        sendRequest(
          "post",
          "api/v2/get-lattitude-longitude/",
          data,
          getRequestedHeader(),
          latLangSuccess,
          requestFailure
        );
      }
    }
  }, [targetIdList]);

  /**
   * Function to be executed on marker click
   * @param {id}
   */
  const onMarkerClick = (id) => {
    if (id) {
      history.push({
        pathname: "/targetProfile",
        search: `?id=${id}`,
      });
    }
  };

  /**
   * Function to select color based on rating
   * @param {Object}
   */
  const selectColor = (country) => {
    const rating =
      country.targets && country.targets.length && country.targets[0].score;
    let riskColor;
    if (rating > 0 && rating <= RISK_CATEGORY_RANGE.LOW_RISK_LIMIT) {
      riskColor = RISK_CATEGORY_COLORS.LOW_RISK;
    } else if (
      rating > RISK_CATEGORY_RANGE.LOW_RISK_LIMIT &&
      rating <= RISK_CATEGORY_RANGE.MODERATE_RISK_LIMIT
    ) {
      riskColor = RISK_CATEGORY_COLORS.MODERATE_RISK;
    } else if (
      rating > RISK_CATEGORY_RANGE.MODERATE_RISK_LIMIT &&
      rating <= RISK_CATEGORY_RANGE.HIGH_RISK_LIMIT
    ) {
      riskColor = RISK_CATEGORY_COLORS.HIGH_RISK;
    } else if (
      rating > RISK_CATEGORY_RANGE.HIGH_RISK_LIMIT &&
      rating <= RISK_CATEGORY_RANGE.CRITICAL_RISK_LIMIT
    ) {
      riskColor = RISK_CATEGORY_COLORS.CRITICAL_RISK;
    } else {
      riskColor = "#fff";
    }
    return riskColor;
  };

  const mapCountries = window.geoData && window.geoData.features;

  useEffect(() => {
    setCountries(null);
    if (mapCountries) {
      for (let i = 0; i < mapCountries.length; i++) {
        const mapCountry = mapCountries[i];
        const matchedCountry = modifyNPData.find(
          (country) =>
            country.name.toLowerCase() ===
            mapCountry.properties.ADMIN.toLowerCase()
        );
        mapCountry.properties.risk_categories = 0;
        mapCountry.properties.color = "#fff";
        mapCountry.properties.id = 0;
        if (matchedCountry != null) {
          mapCountry.properties.risk_categories = matchedCountry.targets;
          mapCountry.properties.id = Number(matchedCountry.id);
          mapCountry.properties.color = selectColor(matchedCountry);
        }
      }
      setCountries(mapCountries);
    }
  }, [modifyNPData]);

  /**
   * Function to be executed on country hover
   * @param {Array}
   */
  const onCountryHover = (layer, countryRiskCat, countryName) => {
    if (countryRiskCat.length) {
      let table = `<div style="font-size:12px; font-weight:600">${countryName}</div>`;
      table += '<table style="font-size:12px;">';
      table +=
        '<tr style="margin-bottom:2px;"><th align="left">Risk Domain</th><th>Rating</th></tr>';
      countryRiskCat.forEach((data) => {
        table += "<tr>";
        table += `<td >${data.name}</td>`;
        table += `<th>${data.score}</th>`;
        table += "</tr>";
      });
      table += "</table>";
      layer.bindTooltip(table).openTooltip();
    } else {
      layer.bindTooltip(countryName).openTooltip();
    }
  };

  const onCountryHoverSelected = (layer, item) => {
    let table = `
    <div style="width:200px">
      <table style="width:100%; padding: 5px 2px">
        <tr>
          <th style="width:80%; text-align:left; font-size:20px; color:#211008">${item.name}</th>
          <th style="width:20%; text-align:right; font-size:18px; color:#FF691F">${item.targets[0].score}</th> 
        </tr>
        <tr>
          <th style="width:80%; text-align:left">Targets</th>
        </tr>
      </table>`;
    {
      item.targets &&
        item.targets.length &&
        item.targets.forEach(
          (target) =>
            (table += ` <div style="color: #FF691F; cursor: pointer; padding: 2px 5px;">
                          <a style="color: #FF691F" href="/targetProfile?id=${target.id}">
                          ${target.name}
                          </a>
                        </div>`)
        );
    }
    table += "</div>";
    layer.bindPopup(table).openPopup();
  };

  /**
   * Function to be executed on creating the map
   * @param {Object} error
   */
  const onEachCountry = (country, layer) => {
    layer.options.fillColor = country.properties.color;
    const countryRiskCat = country.properties.risk_categories;
    const countryName = country.properties.ADMIN;
    const selected = modifyNPData.find(
      (np) => np.name === country.properties.ADMIN
    );

    layer.on({
      click: () => (selected ? onCountryHoverSelected(layer, selected) : null),
      mouseover: () =>
        !selected ? onCountryHover(layer, countryRiskCat, countryName) : null,
    });
  };

  const getCityMarker = () => {
    if (cityData.length) {
      return cityData.map((item) => (
        <Marker
          key={item.id}
          position={[item.lattitude, item.longitude]}
          icon={selectMarkerIcon(item.rating)}
        >
          <Popup className={classes.popupMap}>
            <table className={classes.popupTable}>
              <colgroup>
                <col span="1" style={{ width: "83%" }} />
                <col span="2" style={{ width: "15%" }} />
              </colgroup>
              <tr>
                <th>
                  <Typography variant="h5">{item.name}</Typography>
                </th>
                <th>
                  <Typography variant="h6" color="primary">
                    {item.targets[0].score}
                  </Typography>
                </th>
              </tr>
              <tr>
                <th>Targets</th>
              </tr>
            </table>
            {item.targets &&
              item.targets.length &&
              item.targets.map((target) => (
                <div
                  className={classes.popupText}
                  onClick={() => onMarkerClick(target.id)}
                >
                  {target.name}
                </div>
              ))}
          </Popup>
        </Marker>
      ));
    }
    return null;
  };

  return (
    <Fragment>
      {targetIdList && targetIdList.length ? (
        <WorldmapView
          ZOOM_LEVEL={ZOOM_LEVEL}
          center={center}
          onEachCountry={onEachCountry}
          data={countries}
          countryStyle={countryStyle}
          title="Location Risk Concentration"
        >
          {getCityMarker()}
        </WorldmapView>
      ) : modifyNPData && modifyNPData.length ? (
        <WorldmapView
          ZOOM_LEVEL={ZOOM_LEVEL}
          center={center}
          onEachCountry={onEachCountry}
          data={countries}
          countryStyle={countryStyle}
          title="Location Risk Concentration"
        ></WorldmapView>
      ) : (targetIdList && targetIdList.length === 0) ||
        (modifyNPData && modifyNPData.length === 0) ? (
        <>
          <NoDataElement content="Not subscribed for Location or Third Party / N’th Party Location Tagging" />
          <WorldmapView
            ZOOM_LEVEL={ZOOM_LEVEL}
            center={center}
            onEachCountry={onEachCountry}
            data={countries}
            countryStyle={countryStyle}
            title="Location Risk Concentration"
          ></WorldmapView>
        </>
      ) : (
        <NoDataElement content="Loading..." />
      )}
    </Fragment>
  );
};

export default MapViewNP;
