import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import { Box, Divider, Typography } from '@material-ui/core';
import { getRequestedHeader } from '../helper/commonHelper';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import FroalaEditor from 'react-froala-wysiwyg';
import InputTextField from './common/InputTextField';
import LoaderView from './common/LoaderView';
import Modal from '@material-ui/core/Modal';
import React, { useRef, useState } from 'react';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import sendRequest from '../httpRequest/index';

const breakpoints = createBreakpoints({});

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },

    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        width: '60%',
        boxShadow: theme.shadows[5],
        [breakpoints.down('xs')]: {
            width: '100%',
        },
        [breakpoints.between('sm', 'md')]: {
            width: '100%',
        },
        '&:focus': {
            outline: 'none',
        },
    },

    inpuPart: {
        '& input': {
            padding: '12px 45px !important',
        },
    },

    AddPoupSec: {
        width: '100%',
        textAlign: 'right',
        paddingRight: '20px',
        marginBottom: '10px',
        '& button': {
            height: '45px',
            marginTop: '15px',
            cursor: 'pointer',
            width: '122px',
            fontSize: '18px',
            borderRadius: '2px',
            backgroundColor: '#E0E0E0',
            border: 'none',
            color: '#5C5C5C',
            '&:nth-child(2)': {
                backgroundColor: '#FF691F',
                color: '#fff',
                width: '110px',
                marginLeft: '20px',
                boxShadow: '0px 2px 12px #092F3E4E',
            },
        },
    },

    techSuBox: {
        [breakpoints.down('xs')]: {
            padding: '15px 8px',
        },
    },
    mandatoryError: {
        fontSize: '14px',
        color: '#f44336',
    },
}));

const TechSupport = ({ propsIsOpen, setTheView }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [subject, changeSubject] = React.useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleClosem = () => {
        setTheView(false);
        setErrorMessage('');
    };
    const froala = useRef();

    if (open !== propsIsOpen) {
        setOpen(propsIsOpen);
    }

    /**
   * Function that is called on change of Subject intput text box.
   * @param {Object} Event Object.
   */
    const onSubjectChange = ({ target }) => {
        changeSubject(target.value);
        setErrorMessage('');
    };

    const validateFields = () => {
        if (subject && froala.current && froala.current.editor.html && froala.current.editor.html.get()) {
            return true;
        }
        return false;
    };

    /**
   * Function which is called to Submit the Tech Support Query.
   */
    const submitTechQuery = () => {
        if (validateFields()) {
            setIsLoading(true);
            sendRequest(
                'post',
                '/api/v2/accounts/ask-query/',
                {
                    query_type: 'tech-support',
                    subject,
                    message: froala.current.editor.html.get(),
                    target: -1,
                    additional_info: { targetName: '', page_url: window.location.href },
                },
                getRequestedHeader(),
                ({ data }) => {
                    setIsLoading(false);
                    if (data === 'Thank you') {
                        toast.success(
                            'Your support request is submitted, we will revert back to you soon.'
                        );
                        changeSubject('');
                        froala.current.editor.html.set('');
                        handleClosem();
                    }
                },
                (error) => {
                    setIsLoading(false);
                    setErrorMessage(error.message);
                }
            );
        } else {
            setErrorMessage('Enter Required Fields (Subject & Message).');
        }
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClosem}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    <Box px={4} py={2} className={classes.techSuBox}>
                        <Typography className={classes.fontSetPortPoup} variant="h6">
                            Contact Tech Support
                        </Typography>
                        <Box pt={1} className={classes.mainTableSec}>
                            <Typography
                                className={classes.fontSetPortPoup}
                                variant="subtitle2"
                            >
                                Have a doubt or Question or Need any help, drop a message to us.
                            </Typography>
                        </Box>
                        <Box py={3}>
                            <InputTextField
                                type="text"
                                value={subject}
                                placeholder="Subject"
                                name="Subject"
                                className={classes.inpuPart}
                                onChange={onSubjectChange}
                                onKeyPress={() => {
                                    setErrorMessage('');
                                }}
                            />
                        </Box>
                        <Box py={3}>
                            <FroalaEditor
                                ref={froala}
                                tag="textarea"
                                config={{
                                    attribution: false,
                                    height: 150,
                                    toolbarButtons: {
                                        moreText: {
                                            // eslint-disable-next-line max-len
                                            buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting'],
                                        },
                                        moreParagraph: {
                                            // eslint-disable-next-line max-len
                                            buttons: ['alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
                                        },
                                    },
                                    events: {
                                        focus: () => {
                                            setErrorMessage('');
                                        },
                                    },
                                    key: 'BWC6D-16B3C2F3F1C2A9vC-22D-17zD6G-10eabB-31mbE-13dG-10vwI2C-21rscD3F3H3D2G3A4D4B6D2D1==',
                                }}
                                styles={{ height: '400px' }}
                            />
                        </Box>
                        <span className={classes.mandatoryError}>{errorMessage}</span>
                    </Box>
                    <Divider className={classes.poupHr} />
                    <Box className={classes.AddPoupSec}>
                        <button
                            type="button"
                            className={classes.bottomPoupButton}
                            onClick={handleClosem}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            className={classes.bottomPoupButton}
                            onClick={submitTechQuery}
                        >
                            Send
                        </button>
                    </Box>
                    {isLoading ? <LoaderView /> : null}
                </div>
            </Fade>
        </Modal>
    );
};

export default TechSupport;
