let defaultTargetState = {
    targetTypes: []
};

function TargetTypesReducer(state = defaultTargetState, action){
    switch(action.type){
        case 'SET_TARGET_TYPES': 
            return action.payload;
        default: 
            return state;
    }
}

export default TargetTypesReducer;