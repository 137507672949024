function setAnalystTargets(analystTargets) {
    return {
        type: 'SET_ANALYST_TARGETS',
        payload: {
            analystTargets,
        },
    };
}

export default setAnalystTargets;
