import {
    Box, Divider, TextField, TextareaAutosize, Typography, makeStyles,
} from '@material-ui/core';
import { emailRegex } from '../../common/validationRegex';
import { getRequestedHeader } from '../../helper/commonHelper';
import CustomModal from './CustomModal';
import Loader from './LoaderView';
import NoDataElement from './NoDataElement';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import React, { useState } from 'react';
import sendRequest from '../../httpRequest';

const useStyles = makeStyles(() => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    searchPoupInput: {
        position: 'relative',
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
        fontSize: 16,
        fontFamily: 'Gilroy-Semibold',
    },
    poupInputLab: {
        backgroundColor: '#D5DEE112',
        color: '#00364B',
        border: '0.800000011920929px solid #D5DEE1',
        width: '100%',
        height: '48px',
        paddingLeft: '15px',
        fontSize: 16,
        fontFamily: 'Gilroy-Semibold',
    },
    popupTextArea: {
        backgroundColor: '#D5DEE112',
        color: '#00364B',
        border: '0.800000011920929px solid #D5DEE1',
        width: '100%',
        fontSize: 16,
        fontFamily: 'Gilroy-Semibold',
        padding: '15px',
        borderRadius: '4px',
    },
    emailField: {
        width: '100%',
        '& input': {
            fontSize: '16px',
            fontFamily: 'Gilroy-Semibold',
            padding: '14px',
            color: '#00364B',
        },
    },
    poupHr: {
        margin: '10px -32px',
    },

    bottomPoupButton: {
        color: '#fff',
        height: '48px',
        width: '125px',
        fontSize: '18px',
        borderRadius: '2px',
        border: 'none',
        backgroundColor: '#428BCA',
        cursor: 'pointer',
    },
    modalBtnContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    recipientElem: {
        cursor: 'pointer',
        color: '#428BCA',
        display: 'inline-block',
        '& span': {
            verticalAlign: 'top',
            marginRight: '7px',
        },
    },
    perIcon: {
        verticalAlign: 'center',
    },
    errorElem: {
        color: '#f44336',
    },
}));

const ShareComponent = (props) => {
    const classes = useStyles();
    const {
        open, shareAPI, shareMethod, closeShare, requestParams, onSuccess
    } = props;
    const [recipientCount, setRecipientCount] = useState(1);
    const [recipients, setRecipients] = useState([]);
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [recipientError, setRecipientError] = useState([false]);
    const [recipientErrorMessage, setRecipientErrorMessage] = useState([]);
    const [finalContent, setFinalContent] = useState('');
    const [sharing, setSharing] = useState(false);

    /**
     * Function to add recipient count
     */
    const addMoreRecipient = () => {
        setRecipientCount((prevState) => prevState + 1);
    };

    /**
     * Function to update subject
     * @param {Object} event
     */
    const handleSubjectChange = (event) => {
        const { value } = event.target;
        setSubject(value);
    };

    /**
     * Function to update message
     * @param {Object} event
     */
    const messageChange = (event) => {
        const msg = event.target.value;
        setMessage(msg);
    };

    /**
     * Callback function on share API success
     * @param {Object} res
     */
    const shareSuccess = (res) => {
        setSharing(false);
        onSuccess(res);
        // setFinalContent(res.data.meeage);
    };

    /**
     * Callback function on request failure
     * @param {Object} error
     */
    const requestFailure = (error) => {
        setSharing(false);
        if (error.response && error.response.data) {
            setFinalContent(error.response.data.errorMessage);
        }
    };

    /**
     * Function to validate recipients email
     */
    const isRecipientsInValid = () => {
        let isInvalid = false;
        recipients.map((rec) => {
            if (!emailRegex.test(rec)) {
                isInvalid = true;
            }
        });
        return isInvalid;
    };

    /**
     * Function to handle done click
     */
    const handleDoneClick = () => {
        if (!(recipients.length === 0 || isRecipientsInValid())) {
            setFinalContent('');
            setSharing(true);
            const formData = new FormData();
            formData.append('recipients', recipients.join(','));
            formData.append('subject', subject);
            formData.append('message', message);
            if (requestParams) {
                Object.keys(requestParams).forEach((key) => {
                    formData.append(key, requestParams[key]);
                });
            }
            sendRequest(
                shareMethod,
                shareAPI,
                formData,
                getRequestedHeader(),
                shareSuccess,
                requestFailure
            );
        } else if (recipients.length === 0) {
            setRecipientError([true]);
            setRecipientErrorMessage(['Email address is mandatory']);
        }
    };

    /**
     * Function to add more recipient
     * @param {Object} event
     */
    const addRecipient = (event) => {
        const email = event.target.value;
        const index = event.target.id;
        const newRecipients = recipients.filter(() => true);
        newRecipients[index] = email;
        setRecipients(newRecipients);
    };

    /**
     * Function to validate email
     * @param {Object} event
     */
    const validateEmail = (event) => {
        const { value, id } = event.target;
        const error = recipientError.filter(() => true);
        error[id] = true;
        const errorMessage = recipientErrorMessage.filter(() => true);
        if (value === '') {
            errorMessage[id] = 'Email address is mandatory';
            setRecipientError(error);
            setRecipientErrorMessage(errorMessage);
        } else if (!emailRegex.test(value)) {
            errorMessage[id] = 'Email address is invalid';
            setRecipientError(error);
            setRecipientErrorMessage(errorMessage);
        }
    };

    /**
     * Function to reset error
     * @param {Object} event
     */
    const resetError = (event) => {
        const { id } = event.target;
        const error = recipientError.filter(() => true);
        error[id] = false;
        const errorMessage = recipientErrorMessage.filter(() => true);
        errorMessage[id] = '';
        setRecipientError(error);
        setRecipientErrorMessage(errorMessage);
    };

    /**
     * Function to handle close and clear all state
     */
    const handleCloseClick = () => {
        setRecipientCount(1);
        setRecipients([]);
        setMessage('');
        setSubject('');
        setRecipientError([false]);
        setFinalContent('');
        closeShare(false);
    };

    const recipientCountElements = [];
    for (let i = 0; i < recipientCount; i += 1) {
        recipientCountElements.push(
            <Box py={2} key={i}>
                <TextField
                    type="email"
                    id={i.toString()}
                    name="email"
                    className={classes.emailField}
                    variant="outlined"
                    placeholder="Recipient Email Address"
                    value={recipients[i]}
                    onChange={addRecipient}
                    onBlur={validateEmail}
                    onFocus={resetError}
                />
                {
                    recipientError[i] ? <span className={classes.errorElem}>{recipientErrorMessage[i]}</span> : null
                }
            </Box>
        );
    }

    const modalElements = (
        <Box>
            <Box className={classes.mainSearchPoupInput}>
                <Box py={2} className={classes.searchPoupInput}>
                    <TextField
                        type="text"
                        className={classes.emailField}
                        placeholder="Subject"
                        onChange={handleSubjectChange}
                        // value={editLabelDetails.labelName}
                        variant="outlined"
                    />
                </Box>
                {recipientCountElements}
                <Box>
                    <Typography className={classes.recipientElem} variant="subtitle2" onClick={addMoreRecipient}>
                        {' '}
                        <span>
                            <PersonAddIcon className={classes.perIcon} />
                        </span>
                        Add Recipients
                    </Typography>
                </Box>
                <Box py={2} className={classes.textAreaCont}>
                    <TextareaAutosize placeholder="Add your message here" onChange={messageChange} style={{ height: '100px' }} className={classes.popupTextArea} />
                </Box>
                {
                    finalContent ? <NoDataElement content={finalContent} /> : null
                }
            </Box>
            <Divider className={classes.poupHr} />
            <Box className={classes.modalBtnContainer}>
                <Box className={classes.mainBottonButton}>
                    <button
                        type="button"
                        className={classes.bottomPoupButton}
                        style={{ backgroundColor: '#E0E0E0', color: '#5C5C5C' }}
                        onClick={handleCloseClick}
                    >
                        Cancel
                    </button>
                </Box>
                <Box className={classes.mainBottonButton}>
                    <button
                        type="button"
                        className={classes.bottomPoupButton}
                        style={{ marginLeft: '12px' }}
                        onClick={handleDoneClick}
                    >
                        Share
                    </button>
                </Box>
            </Box>
            {
                sharing ? <Loader /> : null
            }
        </Box>
    );

    return (
        <CustomModal
            modalData={modalElements}
            open={open}
            classModal={classes.modal}
            handleClose={handleCloseClick}
            title="Easy Share"
        />
    );
};

export default ShareComponent;
