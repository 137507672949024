const defaultAnalystState = {
    analystTargets: [],
};

function AnalystTargetsReducer(state = defaultAnalystState, action) {
    switch (action.type) {
    case 'SET_ANALYST_TARGETS':
        return action.payload;
    default:
        return state;
    }
}

export default AnalystTargetsReducer;
