import React, { useState } from 'react';
import { Box, Divider, Tooltip, Typography, FormControlLabel, RadioGroup } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CompositeScore from './CompositeScoreView';
import CustomRadio from '../common/CustomRadio';
import PortfolioContainer from '../../containers/PortfolioComparisonContainer';
import ScoreFromFindings from './ScoreFromFindingView';
import TopTenTargets from './TopTenTargetsView';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { getFormattedDate } from '../../helper/commonHelper';

const CustomizedTooltip = withStyles(() => ({
    tooltip: {
        maxWidth: 220,
        fontSize: '14px',
        border: '1px solid #dadde9',
    },
}))(Tooltip);

const breakpoints = createBreakpoints({});

const useStyles = makeStyles((theme) => ({
    fontSetPortImg: {
        width: '80%',
    },

    porSnap: {
        border: '1px solid #D8D8D8',
        borderRadius: '2px',
        padding: '12px 10px',
        [breakpoints.down('xs')]: {
            padding: '12px 8px',
        },
    },

    radioSecion: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },

    mainFontSetPortImg: {
        display: 'inline-block',
        width: '5%',
        verticalAlign: 'middle',
        [breakpoints.between('1000', '1350')]: {
            width: '7%',
        },
        [breakpoints.down('xs')]: {
            width: '10%',
        },
    },

    chartImgSec: {
        textAlign: 'center',
        '& img': {
            width: '60%',
        },
    },

    hrSec: {
        margin: '19px -20px 0px -20px',
    },

    rightSideScroll: {
        height: '95vh',
        overflow: 'hidden',
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },

    fontSetPort: {
        color: '#211008',
    },

    radioSecionFont: {
        color: '#211008',
        fontFamily: 'Gilroy-Semibold',
        [breakpoints.between('1000', '1350')]: {
            fontSize: '15px',
        },
    },
}));

const PortfolioSnapshot = (props) => {
    const classes = useStyles();
    const [currentView, setCurrentView] = useState('compositeScore');

    const {
        raasAccount, raasAccountSelected, quarter, year
    } = props;

    /**
   * Function to handle change in current view
   * @param {Object} event
   */
    const handleRadioChange = (event) => {
        setCurrentView(event.target.value);
    };

    let allScoreView; let compositeScoreView; let
        portfolioComparisonView;
    if (currentView === 'scoreFromFindings') {
        allScoreView = (
            <ScoreFromFindings
                targetId={props.targetId}
                reportId={props.reportId}
                quarter={props.quarter}
                year={props.year}
                label={props.label}
                raasAccountSelected={raasAccountSelected}
            />
        );
    } else if (currentView === 'compositeScore') {
        compositeScoreView = (
            <CompositeScore
                targetId={props.targetId}
                reportId={props.reportId}
                quarter={props.quarter}
                year={props.year}
                label={props.label}
                raasAccountSelected={raasAccountSelected}
            />
        );
    } else {
        portfolioComparisonView = (
            <PortfolioContainer
                targetId={props.targetId}
                reportId={props.reportId}
                quarter={props.quarter}
                year={props.year}
                label={props.label}
                raasAccount={raasAccount}
                categories={props.categories}
            />
        );
    }

    let title = `as of ${getFormattedDate(new Date(), 'dd/MMM/yy')}`;
    if (quarter && year) {
        title = `for ${quarter}, Year - ${year}`;
    } else if (year) {
        title = `for Year - ${year}`;
    } else if (quarter) {
        title = `for QTR - ${quarter.split('Q')[1]}`;
    }

    return (
        <Box className={classes.porSnap}>
            <Typography className={classes.fontSetPort} variant="h5">
                <span className={classes.mainFontSetPortImg}>
                    <TrendingUpIcon style={{ color: '#FF7841', fontSize: '32px' }} />
                    {' '}
                </span>
                {' '}
                Portfolio
                {' '}
                {title}
            </Typography>

            <Box className={classes.rightSideScroll}>
                <Box>
                    <RadioGroup
                        classes={{ root: classes.radioSecion }}
                        aria-label="gender"
                        name="gender1"
                        onChange={handleRadioChange}
                        defaultValue="compositeScore"
                    >
                        {/* <FormControlLabel
                            classes={{ label: classes.radioSecionFont }}
                            value="scoreFromFindings"
                            control={<CustomRadio />}
                            label="By Risk Finding"
                        /> */}
                        <FormControlLabel
                            classes={{ label: classes.radioSecionFont }}
                            value="compositeScore"
                            control={<CustomRadio />}
                            label={(
                                <CustomizedTooltip
                                    title="Representation of your portfolio of targets distributed
                                    by impact levels based on Composite Rating"
                                >
                                    <Box>By Composite Rating</Box>
                                </CustomizedTooltip>
                            )}
                        />
                        <FormControlLabel
                            classes={{ label: classes.radioSecionFont }}
                            value="portfolioComparison"
                            control={<CustomRadio />}
                            label={(
                                <CustomizedTooltip
                                    title="Enables you to compare your portfolio against
                                    Supply Wisdom portfolio based on Composite Risk Rating or by Risk Domains. "
                                >
                                    <Box>Portfolio Comparison</Box>
                                </CustomizedTooltip>
                            )}
                        />
                    </RadioGroup>
                </Box>
                <Box pt={2} className={classes.chartImgSec}>
                    {currentView === 'scoreFromFindings' ? allScoreView : null}
                    {currentView === 'compositeScore' ? compositeScoreView : null}
                    {currentView === 'portfolioComparison'
                        ? portfolioComparisonView
                        : null}
                </Box>
                <Divider className={classes.hrSec} />
                <TopTenTargets
                    targetId={props.targetId}
                    reportId={props.reportId}
                    quarter={props.quarter}
                    year={props.year}
                    label={props.label}
                    categories={props.categories}
                    raasAccountSelected={raasAccountSelected}
                />
            </Box>
        </Box>
    );
};

export default PortfolioSnapshot;
