import { Box } from '@material-ui/core';
import AskAnalystContainerView from '../../containers/AskAnalystContainerView';
import NoDataElement from '../common/NoDataElement';
import React, { Fragment } from 'react';

const EmbedView = ({ link }) => (
    <Box>
        {link
            ? (
                <Fragment>
                    <AskAnalystContainerView />
                    <iframe title="dashboard" width="100%" frameBorder="0" src={link} style={{ height: '100vh' }} />
                </Fragment>
            )
            : <NoDataElement content="No data to display" />}
    </Box>
);
export default EmbedView;
