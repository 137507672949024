import { emailRegex, passwordRegex, phoneNumberRegex } from './validationRegex';
import errorTypes from './errorMessages';

/**
 * Fetches messages on the basis of the code.
 * @param  {Integer} code [Code Number.]
 * @return {Object}       [Error description object.]
 */
function fetchErrorFromCode(code) {
    const error = errorTypes.filter((errorType) => errorType.errorCode === code)[0];
    return error;
}

/**
 * To validate the value is empty or not
 * @param  {Object} value  [Error Object]
 * @return {Integer}       [Error/Success Code]
 */
function validateMandatory(value) {
    if (value === null || value === undefined) {
        value = '';
    }
    const trimmedValue = value.trim();

    if (!trimmedValue) {
        return fetchErrorFromCode(1);
    }
    return null;
}

/**
 * To validate the value is empty or not
 * @param  {String} value  [Input String]
 * @return {Integer}       [Error/Success Code]
 */
function checkEmailProvider(email) {
    const domain = window.location.origin;
    const HOST = process.env.REACT_APP_BETA_END_POINT_HOST;
    const emails = ['gmail', 'google', 'googlemail', 'outlook', 'yahoo', 'aim', 'icloud', 'zoho', 'myway', 'hotmail', 'facebook'];
    const domains = {
        com: [
            'isg',
            'hiperos',
            'protiviti',
            'alsbridge',
            'hfsresearch',
            'gartner',
            'forrester',
            'spendmatters',
            //'pwc',
            'dnb',
            'bmiresearch',
            'lexisnexis',
            'metricstream', 'achilles', 'rapidratings', 'resilinc', 'emc',
        ],
        net: ['riskmethods'],
    };
    if (email) {
        email = email.split('@');
        email = email[1].split('.');
        const domainList = domains[email[1]];
        if ((domain === HOST) && emails.indexOf(email[0]) !== -1 && ((domainList && domainList.indexOf(email[0]) === -1) || !domainList)) {
            return false;
        }
        if (emails.indexOf(email[0]) === -1 && ((domainList && domainList.indexOf(email[0]) === -1) || !domainList)) {
            return false;
        }
    }
    return true;
}

/**
 * To validate Emails.
 * @param  {String} value  [Input String]
 * @return {Integer}       [Error/Success Code]
 */
function validateEmail(value) {
    const mandatoryCheck = validateMandatory(value);

    if (mandatoryCheck && mandatoryCheck.errorCode === 1) {
        return fetchErrorFromCode(2);
    }

    const isValid = emailRegex.test(value);

    if (!isValid) {
        return fetchErrorFromCode(3);
    }

    const isDomainBlocked = checkEmailProvider(value);

    if (isDomainBlocked) {
        return fetchErrorFromCode(14);
    }

    return null;
}

/**
 * To validate Password.
 * @param  {String} value  [Input String]
 * @return {Integer}       [Error/Success Code]
 */
function validatePassword(value) {
    const mandatoryCheck = validateMandatory(value);

    if (mandatoryCheck && mandatoryCheck.errorCode === 1) {
        return fetchErrorFromCode(4);
    }

    const isValid = passwordRegex.test(value);

    if (!isValid) {
        return fetchErrorFromCode(5);
    }

    return null;
}

/**
 * To validate Phone Number.
 * @param  {String} value  [Input String]
 * @return {Integer}       [Error/Success Code]
 */
function validatePhoneNumber(value) {
    const isValid = phoneNumberRegex.test(value);

    if (!isValid) {
        return fetchErrorFromCode(6);
    }
    return null;
}

/**
 * Common calling Function.
 * @param  {String} type   [To determine the type of Validation]
 * @param  {String} value  [Input String]
 * @return {Integer}       [Error/Success Code]
 */
function validateInput(type, value) {
    switch (type) {
    case 'email': return validateEmail(value);
    case 'password': return validatePassword(value);
    case 'phone': return validatePhoneNumber(value);
    case 'mandatory': return validateMandatory(value);
    default: return validateMandatory(value);
    }
}

export {
    validateInput,
    fetchErrorFromCode,
};
