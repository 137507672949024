import FusionCharts from 'fusioncharts';

const FC_LICENSE = 'PtB2dF-7wB4C5C3F4D3A3C3G3B8B1B4B1udnE4H1cgkA-13lB4A3C-7izA5C5B3D6F5F4H4E1B9A5C3C4C4E1A2E1mllC7E2B4dD4F3H3yxoF4E2D3isbB6C1E3ycaC1G4B1B8B4A4B3D3B4H3A33fhhD8B1SG4lhJ-7A9C11A5veE3NA1A1sslE2D6G2F3H3J3B7A5A4A2F4B2D1H4z==';

FusionCharts.options.license({
    key: FC_LICENSE,
    creditLabel: false,
});

const AUTH_URL = `https://${process.env.REACT_APP_BE_END_POINT_HOST}/sso/?sso`;
const DECRYPTION_MASTER_KEY = '1234567890123456';

export { FusionCharts, AUTH_URL, DECRYPTION_MASTER_KEY };
